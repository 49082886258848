import React, { Fragment, useRef, useState } from 'react'
import { Grid, Button, Image, Label, Icon, Segment, Message, Header, Container, Modal, Form, Dropdown, Portal, Tab, Table } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import UserService from '../../../service/UserService';
import AuthenService from '../../../service/Authen';
import scb_img from "../../../assets/images/bank/scb.svg"
import bay_img from "../../../assets/images/bank/bay.svg"
import ktb_img from "../../../assets/images/bank/ktb.svg"
import kbnk_img from "../../../assets/images/bank/kbnk.svg"
import tmb_img from "../../../assets/images/bank/tmb.svg"
import bbl_img from "../../../assets/images/bank/bbl.svg"
import gsb_img from "../../../assets/images/bank/gsb.svg"
import baac_img from "../../../assets/images/bank/baac.svg"
import ghba_img from "../../../assets/images/bank/ghba.svg"
import kkba_img from "../../../assets/images/bank/kkba.svg"
import tbnk_img from "../../../assets/images/bank/tbnk.svg"
import ttb_img from "../../../assets/images/bank/ttb.svg"
import profile_icon from "../../../assets/images/profile-icon.png"
import { useHistory } from 'react-router-dom';
import Config from "../../../config/config";
import config from '../../../config/config';
import { AFIcon, ProfileDetailIcon, HistoryIcon } from '../../../assets/images/svg/svg-icon'
import ReCAPTCHA from "react-google-recaptcha";

const Setting = () => {
    const [openAddBankModal, setOpenAddBank] = useState(false);

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [openChangePassModal, setOpenChangePassModal] = useState(false);
    const [changePassMsg, setChangePassMsg] = useState("");
    const [changePassStatus, setChangePassStatus] = useState(false);
    const [changePassLoading, setChangePassLoading] = useState(false);

    const [openChangeNameModal, setOpenChangeNameModal] = useState(false);
    const [nameUser, setNameUser] = useState(UserService.getUser().name || '');
    const [oldNameUser, setOldNameUser] = useState(UserService.getUser().name || '');
    const [isLoadingModalChangeName, setIsLoadingModalChangeName] = useState(false);
    const [changeNameMsg, setChangeNameMsg] = useState("");

    const [activeTab, setActiveTab] = useState(0);
    const [isLoadingHistory, setIsLoadingHistory] = useState(false)
    const [historyHuay, setHistoryHuay] = useState([])
    const [historyGame, setHistoryGame] = useState([])
    const [historyGameLimit, setHistoryGameLimit] = useState(10)
    const [historyHuayLimit, setHistoryHuayLimit] = useState(1)
    const [historySelect, setHistorySelect] = useState('หวย')

    const history = useHistory();
    const handelChangePassword = () => {
        if (validatePassword()) {
            setChangePassLoading(true)
            AuthenService.changePassword(newPassword, oldPassword).then(res => {
                if (res.status) {
                    setChangePassStatus(true)
                    setOpenChangePassModal(false)
                    setChangePassMsg("")
                } else {
                    setChangePassMsg(res.msg)
                }
                setChangePassLoading(false)
            })
        }
    }

    const handelSaveNameUser = async () => {
        setChangeNameMsg("")
        if (nameUser == oldNameUser) return
        if (nameUser != null) {
            setIsLoadingModalChangeName(true);
            await UserService.setNameUser(nameUser).then(async (res) => {
                if (res.status) {
                    setNameUser(res.data.name || "")
                    await UserService.fetchMe()
                    window.location.reload()
                } else {
                    setChangeNameMsg(res.msg)
                }
                setIsLoadingModalChangeName(false)
            })
        }
    }

    const handleSetValueNameUser = (value) => {
        setChangeNameMsg("")
        let name = value.trim()
        setNameUser(name)
    }

    const validatePassword = () => (newPassword === confPassword && newPassword.length >= 6)

    const getListHistoryHuay = (limit = 10) => {
        UserService.getHistoryHuay(limit).then((res) => {
            if (res.status) {
                const handleData = [];
                res.data.wallet.creditHistory.map((item, key) => {
                    //prepair topic
                    let topicText = null;
                    switch (item.type) {
                        case 1:
                            topicText = 'ฝาก';
                            break;
                        case 2:
                            topicText = 'ถอน';
                            break;
                        case 3:
                            topicText = 'แทงหวย';
                            break;
                        case 4:
                            topicText = 'ถูกรางวัล';
                            break;
                        case 5:
                            topicText = 'โอน AF เป็นเครดิต';
                            break;
                        case 6:
                            topicText = 'ถอน AF';
                            break;
                        case 7:
                            topicText = 'ได้ AF';
                            break;
                        case 8:
                            topicText = 'เล่นเกม';
                            break;
                        case 9:
                            topicText = 'ชนะเกม';
                            break;
                        case 10:
                            topicText = 'เสมอเกม';
                            break;
                        case 11:
                            topicText = 'เกมหมดเวลา';
                            break;
                        case 12:
                            topicText = 'คืนเงิน';
                            break;
                        case 13:
                            topicText = 'ดึงเงินคืน';
                            break;
                        default:
                            topicText = 'ไม่มีหัวข้อรายการ'
                    }

                    if (item.type != 6) {
                        handleData.push({
                            topic: topicText,
                            dateTime: item.createAt,
                            money: item.oldValue,
                            moneyAction: ((item.isIncome) ? '+' : '-') + item.actionValue,
                            moneySum: item.newValue,
                            color: (item.isIncome) ? 'green' : 'red',
                            status: (item.isIncome) ? 'positive' : 'negative',
                        })
                    }
                });
                setHistoryHuayLimit(limit)
                setHistoryHuay(handleData)
            }
        })
    }

    const getListHistoryGame = (limit = 10) => {
        UserService.getHistoryGame(limit).then((res) => {
            if (res.status) {
                const handleData = [];
                res.data.wallet.creditHistory.map((item, key) => {
                    //prepair topic
                    let topicText = null;
                    switch (item.type) {
                        case 1:
                            topicText = 'ฝาก';
                            break;
                        case 2:
                            topicText = 'ถอน';
                            break;
                        case 3:
                            topicText = 'แทงหวย';
                            break;
                        case 4:
                            topicText = 'ถูกรางวัล';
                            break;
                        case 5:
                            topicText = 'โอน AF เป็นเครดิต';
                            break;
                        case 6:
                            topicText = 'ถอน AF';
                            break;
                        case 7:
                            topicText = 'ได้ AF';
                            break;
                        case 8:
                            topicText = 'เล่นเกม';
                            break;
                        case 9:
                            topicText = 'ชนะเกม';
                            break;
                        case 10:
                            topicText = 'เสมอเกม';
                            break;
                        case 11:
                            topicText = 'เกมหมดเวลา';
                            break;
                        case 12:
                            topicText = 'คืนเงิน';
                            break;
                        case 13:
                            topicText = 'ดึงเงินคืน';
                            break;
                        default:
                            topicText = 'ไม่มีหัวข้อรายการ'
                    }

                    if (item.type != 6) {
                        handleData.push({
                            topic: topicText,
                            dateTime: item.createAt,
                            money: item.oldValue,
                            moneyAction: ((item.isIncome) ? '+' : '-') + item.actionValue,
                            moneySum: item.newValue,
                            color: (item.isIncome) ? 'green' : 'red',
                            status: (item.isIncome) ? 'positive' : 'negative',
                            detail: item.desc,
                        })
                    }
                });
                setHistoryGameLimit(limit)
                setHistoryGame(handleData)
            }
        })
    }

    // const panes = [
    //     {
    //         menuItem: { key: 'profile', icon: <ProfileDetailIcon style={{ marginRight: 5 }} color={activeTab == 0 ? '#FFF' : "#000"} />, content: 'ข้อมูลส่วนตัว' },
    //         render: () =>
    //             <Container className="profileCustom">
    //                 <Segment style={{ border: 0, boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.1)', padding: 20 }}>
    //                     <Grid>
    //                         <Grid.Column computer='8' tablet='8' mobile='5'>
    //                             <Image src={profile_icon} size='small' className='float-right' />
    //                         </Grid.Column>
    //                         <Grid.Column computer='8' tablet='8' mobile='11'>
    //                             <h4 style={{ 'fontWeight': 'bold', margin: 3 }}>รหัสสมาชิก</h4>
    //                             <span>{UserService.getUser()._id}</span>
    //                             <h4 style={{ 'fontWeight': 'bold', marginBottom: 3, marginTop: 10 }}>ชื่อผู้ใช้งาน </h4>
    //                             {oldNameUser && (<><span>{oldNameUser}</span> <Icon name='pencil' size='small' color='blue' onClick={() => setOpenChangeNameModal(true)} style={{ cursor: 'pointer' }} /><br /></>)}
    //                             <span>{UserService.getUser().username}</span><br /><p />
    //                             {!oldNameUser && (<>
    //                                 <span style={{ color: '#0C79C8', cursor: 'pointer' }} onClick={() => setOpenChangeNameModal(true)}>เพิ่มชื่อเล่น</span><br />
    //                             </>)}
    //                             {/* <span style={{ color: '#0C79C8', cursor: 'pointer'}} onClick={() => setOpenChangePassModal(true)}>เปลี่ยนรหัสผ่าน</span> */}
    //                         </Grid.Column>
    //                     </Grid>
    //                 </Segment>
    //                 {UserService.getBankAccount() ? (
    //                     <Segment className='customeSegment'>
    //                         <Grid>
    //                             <Grid.Column computer='3' tablet='3' mobile='5'>
    //                                 <Image rounded floated='left' src={BankObject.getBankData().image.src} size='tiny' />
    //                             </Grid.Column>
    //                             <Grid.Column computer='11' tablet='11' mobile='8'>
    //                                 <div>
    //                                     <div style={{ 'font-weight': 'bold' }}>{BankObject.getBankData().text}</div>
    //                                     <div>{UserService.getBankAccount().bankNumber.slice(0, 3) + 'XXXX' + UserService.getBankAccount().bankNumber.slice(7, 10)}</div>
    //                                     <div style={{ marginTop: 10 }}>{UserService.getBankAccount().accountName}</div>
    //                                 </div>
    //                             </Grid.Column>
    //                             <Grid.Column computer='2' tablet='2' mobile='3' color='blue' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: '0px 0.28571429rem 0.28571429rem 0px' }} onClick={() => setOpenAddBank(true)}>
    //                                 <Icon name='edit outline' size='big' />
    //                             </Grid.Column>
    //                         </Grid>
    //                     </Segment>
    //                 ) : (
    //                     <Segment className='customeSegment' style={{ color: 'rgb(12, 121, 200)', cursor: 'pointer' }} onClick={() => setOpenAddBank(true)}>
    //                         <center>เพิ่มบัญชีธนาคาร</center>
    //                     </Segment>
    //                 )}

    //                 <Button as='a' href={Config.LineURL} target='_blank' color='green' fluid size='big' style={{ borderRadius: '0.28571429rem' }}><span style={{ fontWeight: 400 }}>ติดต่อเราที่ LINE</span></Button>
    //                 <Button onClick={() => AuthenService.logout().then(() => history.push("/"))} fluid size='big' color='grey' style={{ borderRadius: '0.28571429rem', marginTop: 15 }}><span style={{ fontWeight: 400 }}>ออกจากระบบ</span></Button>

    //                 <Modal dimmer="blurring" size='small' open={openAddBankModal} onClose={() => setOpenAddBank(false)} closeIcon>
    //                     <Modal.Content scrolling>
    //                         {/*  Setting Bank */}
    //                         {(config.enableEditBankAccount == true || config.enableEditBankAccount === 'true') ?
    //                             <BankObject.SettingBankAccount handelSaved={() => { window.location.reload() }} /> :
    //                             !UserService.getBankAccount() ?
    //                                 <BankObject.SettingBankAccount handelSaved={() => { window.location.reload() }} /> :
    //                                 <Message warning >
    //                                     <center>
    //                                         <Message.Header>หากต้องการเปลี่ยนหมายเลขบัญชี</Message.Header>
    //                                         <p>กรุณาติดต่อเจ้าหน้าที่ <a href={config.LineURL} target="_blank">Call Center</a></p>
    //                                     </center>
    //                                 </Message>
    //                         }
    //                     </Modal.Content>
    //                 </Modal>
    //                 <Modal dimmer="blurring" size='small' open={openChangePassModal} closeIcon onClose={() => setOpenChangePassModal(false)} >
    //                     <Header icon='setting' content={<h3>เปลี่ยนรหัสผ่าน</h3>} />
    //                     <Modal.Content scrolling >
    //                         <Form loading={changePassLoading}>
    //                             <Form.Field>
    //                                 <label>รหัสผ่านเดิม</label>
    //                                 <input onChange={(event) => setOldPassword(event.target.value)} type="password" />
    //                             </Form.Field>
    //                             <Form.Field>
    //                                 <label>รหัสผ่านใหม่</label>
    //                                 <input onChange={(event) => setNewPassword(event.target.value)} type="password" />
    //                             </Form.Field>
    //                             <Form.Field>
    //                                 <label>ยืนยันรหัสผ่านใหม่</label>
    //                                 <input onChange={(event) => setConfPassword(event.target.value)} type="password" />
    //                                 <Label className={(validatePassword() || confPassword == "") ? "hidden" : "show"} basic color='red' pointing>
    //                                     รหัสผ่านไม่ถูกต้อง หรือน้อยกว่า 6 อักขระ
    //                                 </Label>
    //                                 {(changePassMsg != "") ? (
    //                                     <center><br /><p style={{ color: "red" }}>{changePassMsg}</p></center>
    //                                 ) : ''}
    //                             </Form.Field>
    //                             <Button type='submit' onClick={() => handelChangePassword()} disabled={(!validatePassword())} positive >บันทึก</Button>
    //                         </Form>
    //                     </Modal.Content>
    //                 </Modal>

    //                 <Modal dimmer="blurring" size='small' open={openChangeNameModal} closeIcon onClose={() => setOpenChangeNameModal(false)}>
    //                     <Header icon='setting' content={<h3>ตั้งค่าชื่อเล่น</h3>} />
    //                     <Modal.Content scrolling>
    //                         <Form loading={isLoadingModalChangeName}>
    //                             <Form.Field>
    //                                 <label>ชื่อเล่น</label>
    //                                 <input onChange={(event) => handleSetValueNameUser(event.target.value)} value={nameUser} type="text" placeholder='กรอกชื่อเล่น' />
    //                             </Form.Field>
    //                             {(changeNameMsg != "") ? (
    //                                 <center><p style={{ color: "red" }}>{changeNameMsg}</p></center>
    //                             ) : ''}
    //                             <center><Button type='submit' onClick={() => handelSaveNameUser()} disabled={nameUser == null || nameUser == oldNameUser} positive >บันทึก</Button></center>
    //                         </Form>
    //                     </Modal.Content>
    //                 </Modal>
    //                 <Portal onClose={() => setChangePassStatus(false)} open={changePassStatus}>
    //                     <Segment
    //                         style={{
    //                             left: '40%',
    //                             position: 'fixed',
    //                             top: '50%',
    //                             zIndex: 1000,
    //                         }}
    //                     >
    //                         <center>
    //                             <Header>เปลี่ยนรหัสผ่านสำเร็จ</Header>
    //                             <Button
    //                                 content='ตกลง'
    //                                 positive
    //                                 onClick={() => setChangePassStatus(false)}
    //                             />
    //                         </center>
    //                     </Segment>
    //                 </Portal>
    //             </Container>
    //     },
    //     {
    //         menuItem: { key: 'history', icon: <HistoryIcon style={{ marginRight: 5 }} color={activeTab == 1 ? '#FFF' : "#000"} />, content: 'ประวัติการเล่น' },
    //         render: () =>
    //             <Container className="profileCustom">
    //                 <div style={{
    //                     padding: 3,
    //                     background: 'linear-gradient(90deg, #790000 0%, #000000 123.12%), #ffffff',
    //                     borderRadius: 8
    //                 }}>
    //                     <Dropdown
    //                         options={
    //                             [
    //                                 {
    //                                     key: 'หวย',
    //                                     text: 'หวย',
    //                                     value: 'หวย',
    //                                 },
    //                                 {
    //                                     key: 'เกม',
    //                                     text: 'เกม',
    //                                     value: 'เกม',
    //                                 }
    //                             ]
    //                         }
    //                         defaultValue={historySelect}
    //                         onChange={(event, data) => {
    //                             setHistorySelect(data.value)
    //                         }}
    //                         className={"profileDropdown"}
    //                     />
    //                 </div>
    //                 <div style={{
    //                     padding: 3,
    //                     background: 'linear-gradient(90deg, #790000 0%, #000000 123.12%), #ffffff',
    //                     borderRadius: 8,
    //                     marginTop: 8
    //                 }}>
    //                     <Segment loading={isLoadingHistory} style={{ marginTop: 0 }}>
    //                         <Table style={{ border: 0, margin: 0, marginBottom: 20 }}>
    //                             <Table.Body>
    //                                 {
    //                                     historySelect === 'หวย' &&
    //                                     historyHuay.map((item, index) => {
    //                                         return (
    //                                             <Table.Row positive={item.myNumberShot} key={index}>
    //                                                 <Table.Cell style={{ width: '50%', paddingLeft: 50, textAlign: 'left', color: '#636363' }}>
    //                                                     <p style={{ color: '#000000', fontWeight: 400, fontSize: 16, marginBottom: 10 }}>{item.topic}</p>
    //                                                     <p style={{ color: '#888', fontWeight: 300, fontSize: 13 }}>{new Date(item.dateTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' }) + " " + new Date(item.dateTime).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)}</p>
    //                                                 </Table.Cell>
    //                                                 <Table.Cell style={{ width: '50%', paddingRight: 50, textAlign: 'right', color: '#636363' }}>
    //                                                     <p style={{ color: item.color, fontWeight: 400, fontSize: 14 }} >{item.moneyAction}</p>
    //                                                 </Table.Cell>
    //                                             </Table.Row>
    //                                         )
    //                                     })

    //                                 }
    //                                 {
    //                                     historySelect === 'เกม' &&
    //                                     historyGame.map((item, index) => {
    //                                         return (
    //                                             <Table.Row positive={item.myNumberShot} key={index}>
    //                                                 <Table.Cell style={{ width: '50%', paddingLeft: 50, textAlign: 'left', color: '#636363' }}>
    //                                                     <p style={{ color: '#000000', fontWeight: 400, fontSize: 16, marginBottom: 5 }}>{item.topic}</p>
    //                                                     <p style={{ color: '#000000', fontWeight: 400, fontSize: 13, marginBottom: 5 }}>{item.detail}</p>
    //                                                     <p style={{ color: '#888', fontWeight: 300, fontSize: 13 }}>{new Date(item.dateTime).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' }) + " " + new Date(item.dateTime).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)}</p>
    //                                                 </Table.Cell>
    //                                                 <Table.Cell style={{ width: '50%', paddingRight: 50, textAlign: 'right', color: '#636363' }}>
    //                                                     <p style={{ color: item.color, fontWeight: 400, fontSize: 14 }} >{item.moneyAction}</p>
    //                                                 </Table.Cell>
    //                                             </Table.Row>
    //                                         )
    //                                     })
    //                                 }
    //                             </Table.Body>
    //                         </Table>
    //                         {
    //                             historySelect === 'หวย' &&
    //                             historyHuay.length >= historyHuayLimit &&
    //                             <center style={{ marginTop: 10 }}>
    //                                 <Button positive onClick={() => getListHistoryHuay(historyHuayLimit + 5)}  > โหลดเพิ่มเติม</Button>
    //                             </center>
    //                         }
    //                         {
    //                             historySelect === 'เกม' &&
    //                             historyGame.length >= historyGameLimit &&
    //                             <center style={{ marginTop: 10 }}>
    //                                 <Button positive onClick={() => getListHistoryGame(historyGameLimit + 5)}  > โหลดเพิ่มเติม</Button>
    //                             </center>
    //                         }
    //                     </Segment>
    //                 </div>

    //             </Container>
    //     }
    // ]
    return (
        <Container className='profileTab'>
            <Container className="profileCustom">
                <Segment className="profileCard">
                    <Grid>
                        <Grid.Column computer='8' tablet='8' mobile='7' style={{ padding: 0 }}>
                            <Image src={profile_icon} className='float-right' />
                        </Grid.Column>
                        <Grid.Column computer='8' tablet='8' mobile='9'>
                            <h4 style={{ 'fontWeight': 'bold', margin: 3 }}>ID ผู้ใช้งาน</h4>
                            <span>{UserService.getUser().username}</span>
                            <h4 style={{ 'fontWeight': 'bold', marginBottom: 3, marginTop: 10 }}>ชื่อเล่น </h4>
                            {oldNameUser && (<><span>{oldNameUser}</span> <Icon name='pencil' size='small' color='blue' onClick={() => setOpenChangeNameModal(true)} style={{ cursor: 'pointer' }} /><br /></>)}
                            {/* <span>{UserService.getUser().username}</span><br /><p /> */}
                            {!oldNameUser && (<>
                                <span style={{ color: '#F4BE1E', cursor: 'pointer' }} onClick={() => setOpenChangeNameModal(true)}>เพิ่มชื่อเล่น</span><br />
                            </>)}
                            <span style={{ color: '#0C79C8', cursor: 'pointer' }} onClick={() => setOpenChangePassModal(true)}>เปลี่ยนรหัสผ่าน</span>
                        </Grid.Column>
                    </Grid>
                </Segment>
                {UserService.getBankAccount() ? (
                    <Segment className='card-bank-user'>
                        <Grid >
                            <Grid.Column computer='3' tablet='3' mobile='5'>
                                <Image rounded floated='left' src={BankObject.getBankData().image.src} size='tiny' />
                            </Grid.Column>
                            <Grid.Column computer='11' tablet='11' mobile='8'>
                                <div>
                                    <div style={{ 'font-weight': 'bold' }}>{BankObject.getBankData().text}</div>
                                    <div>{UserService.getBankAccount().bankNumber.slice(0, 3) + 'XXXX' + UserService.getBankAccount().bankNumber.slice(7, 10)}</div>
                                    <div style={{ marginTop: 10 }}>{UserService.getBankAccount().accountName}</div>
                                </div>
                            </Grid.Column>
                            <Grid.Column computer='2' tablet='2' mobile='3' color='blue' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: '0px 15px 15px 0px' }} onClick={() => setOpenAddBank(true)}>
                                <Icon name='edit outline' size='big' />
                            </Grid.Column>
                        </Grid>
                    </Segment>
                ) : (
                    <Segment className='customeSegment' style={{ color: 'rgb(12, 121, 200)', cursor: 'pointer' }} onClick={() => setOpenAddBank(true)}>
                        <center>เพิ่มบัญชีธนาคาร</center>
                    </Segment>
                )}

                <Button as='a' href={Config.LineURL} target='_blank' fluid size='big' style={{ borderRadius: '0.28571429rem', backgroundColor: "#E7276C", color: "#fff" }}><span style={{ fontWeight: 400 }}>ติดต่อเราที่ LINE</span></Button>
                <Button onClick={() => AuthenService.logout().then(() => history.push("/"))} fluid size='big' color='grey' style={{ borderRadius: '0.28571429rem', marginTop: 15, backgroundColor: "#fff", color: "#000" }}><span style={{ fontWeight: 400 }}>ออกจากระบบ</span></Button>

                <Modal dimmer="blurring" size='small' open={openAddBankModal} onClose={() => setOpenAddBank(false)} closeIcon>
                    <Modal.Content scrolling>
                        {/*  Setting Bank */}
                        {(config.enableEditBankAccount == true || config.enableEditBankAccount === 'true') ?
                            <BankObject.SettingBankAccount handelSaved={() => { window.location.reload() }} /> :
                            !UserService.getBankAccount() ?
                                <BankObject.SettingBankAccount handelSaved={() => { window.location.reload() }} /> :
                                <Message warning >
                                    <center>
                                        <Message.Header>หากต้องการเปลี่ยนหมายเลขบัญชี</Message.Header>
                                        <p>กรุณาติดต่อเจ้าหน้าที่ <a href={config.LineURL} target="_blank">Call Center</a></p>
                                    </center>
                                </Message>
                        }
                    </Modal.Content>
                </Modal>
                <Modal dimmer="blurring" size='small' open={openChangePassModal} closeIcon onClose={() => setOpenChangePassModal(false)} >
                    <Header icon='setting' content={<h3>เปลี่ยนรหัสผ่าน</h3>} />
                    <Modal.Content scrolling >
                        <Form loading={changePassLoading}>
                            <Form.Field>
                                <label>รหัสผ่านเดิม</label>
                                <input onChange={(event) => setOldPassword(event.target.value)} type="password" />
                            </Form.Field>
                            <Form.Field>
                                <label>รหัสผ่านใหม่</label>
                                <input onChange={(event) => setNewPassword(event.target.value)} type="password" />
                                <Label className={(newPassword == '' || newPassword.length >= 6) ? "hidden" : "show"} basic color='red' pointing>
                                    รหัสผ่านต้องมีตั้งแต่ 6 อักขระขึ้นไป
                                </Label>
                            </Form.Field>
                            <Form.Field>
                                <label>ยืนยันรหัสผ่านใหม่</label>
                                <input onChange={(event) => setConfPassword(event.target.value)} type="password" />
                                <Label className={(validatePassword() || confPassword == "") ? "hidden" : "show"} basic color='red' pointing>
                                    รหัสผ่านยืนยันไม่ถูกต้อง หรือน้อยกว่า 6 อักขระ
                                </Label>
                                {(changePassMsg != "") ? (
                                    <center><br /><p style={{ color: "red" }}>{changePassMsg}</p></center>
                                ) : ''}
                            </Form.Field>
                            <Button type='submit' onClick={() => handelChangePassword()} disabled={(!validatePassword())} positive >บันทึก</Button>
                        </Form>
                    </Modal.Content>
                </Modal>

                <Modal dimmer="blurring" size='small' open={openChangeNameModal} closeIcon onClose={() => setOpenChangeNameModal(false)}>
                    <Header icon='setting' content={<h3>ตั้งค่าชื่อเล่น</h3>} />
                    <Modal.Content scrolling>
                        <Form loading={isLoadingModalChangeName}>
                            <Form.Field>
                                <label>ชื่อเล่น</label>
                                <input onChange={(event) => handleSetValueNameUser(event.target.value)} value={nameUser} type="text" placeholder='กรอกชื่อเล่น' />
                            </Form.Field>
                            {(changeNameMsg != "") ? (
                                <center><p style={{ color: "red" }}>{changeNameMsg}</p></center>
                            ) : ''}
                            <center><Button type='submit' onClick={() => handelSaveNameUser()} disabled={nameUser == null || nameUser == oldNameUser} positive >บันทึก</Button></center>
                        </Form>
                    </Modal.Content>
                </Modal>
                <Modal onClose={() => setChangePassStatus(false)} open={changePassStatus}>
                    <Modal.Content >
                        <center>
                            <Header>เปลี่ยนรหัสผ่านสำเร็จ</Header>
                            <Button
                                content='ตกลง'
                                positive
                                onClick={() => setChangePassStatus(false)}
                            />
                        </center>
                    </Modal.Content >
                </Modal>
            </Container>
        </Container>
    )
}

const SettingBankAccount = (props) => {
    const [bankName, setBankName] = useState(UserService.getBankAccount() ? UserService.getBankAccount().bankName : "");
    const [bankID, setBankID] = useState(UserService.getBankAccount() ? UserService.getBankAccount().bankNumber : "");
    const [bankOwner, setBankOwner] = useState(UserService.getBankAccount() ? UserService.getBankAccount().accountName : "");
    const [isLoadingModalSaveBank, setIsLoadingModalSaveBank] = useState(false);
    const [errFlag, setErrFlag] = useState(null);
    const [statusBank, setStatusBank] = useState("check")
    const [lockOwnerName, setLockOwnerName] = useState(false)
    const [recaptchaValue, setRecaptchaValue] = useState("");
    const recaptcha_ref = useRef(null)

    const handelSaveBankAccount = () => {
        setErrFlag(null)
        if (bankName != "" && bankID != "" && bankOwner != "") {
            setIsLoadingModalSaveBank(true);
            UserService.saveBankAccount(bankName, bankID, bankOwner).then(async (res) => {
                if (res.status) {
                    await UserService.fetchMe()
                    await props.handelSaved()
                    setIsLoadingModalSaveBank(false)
                } else {
                    setErrFlag(res.msg)
                    setIsLoadingModalSaveBank(false)
                }
            })
        }
    }

    const handelCheckBankAccount = () => {
        setErrFlag(null)
        if (bankName != "" && bankID != "" && recaptchaValue != "") {
            setIsLoadingModalSaveBank(true);
            UserService.checkBankCaptCha(bankName, bankID, recaptchaValue).then(async (res) => {
                if (res.status) {
                    if (res.data?.status == "0000") {
                        setStatusBank("save")
                        setBankOwner(res.data?.data?.accountName || "")
                        setLockOwnerName(true)
                        setIsLoadingModalSaveBank(false)
                    } else if (res.data?.status == "5009" || res.data?.status == "9999") {
                        setErrFlag(res.data.message)
                        console.log(recaptcha_ref?.current.reset())
                        setRecaptchaValue("")
                        setIsLoadingModalSaveBank(false)
                    } else {
                        setBankOwner(res.data?.accountName || "")
                        setStatusBank("save")
                        setLockOwnerName(false)
                        setIsLoadingModalSaveBank(false)
                    }
                }
                else {
                    setErrFlag(res.msg)
                    setIsLoadingModalSaveBank(false)
                }
            })
        }
    }

    return (
        <Form loading={isLoadingModalSaveBank}>
            <center><p style={{ color: 'red' }}>{errFlag}</p></center>
            <center>
                <ReCAPTCHA
                    style={recaptchaValue ? { display: 'none' } : { marginTop: 15 }}
                    size='normal'
                    sitekey={config.RECAPTCHA_SITE_KEY}
                    onChange={(value) => setRecaptchaValue(value)}
                    ref={recaptcha_ref}
                />
            </center>
            {
                recaptchaValue &&
                <>
                    <Form.Field required>
                        <label >ชื่อธนาคาร</label>
                        <Dropdown
                            placeholder='Select Bank'
                            fluid
                            selection
                            options={BankObject.bankAccountList}
                            onChange={(e, data) => setBankName(data.value)}
                            value={bankName}
                            className="select-bank"
                            disabled={statusBank == "save"}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label>เลขธนาคาร</label>
                        <input placeholder='' type="number" onChange={(event) => setBankID(event.target.value)} value={bankID} disabled={statusBank == "save"} />
                    </Form.Field>
                    {
                        statusBank == "save" &&
                        <Form.Field required>
                            <label>ชื่อเจ้าของธนาคาร</label>
                            <input placeholder='' onChange={(event) => setBankOwner(event.target.value)} value={bankOwner} disabled={lockOwnerName} />
                        </Form.Field>
                    }

                    {
                        statusBank == "check" ?
                            <Button type='submit' onClick={(e) => handelCheckBankAccount()} positive >ตรวจสอบ</Button>
                            :
                            <Button type='submit' onClick={(e) => handelSaveBankAccount()} positive >บันทึก</Button>
                    }
                </>
            }

        </Form>
    )
}

class BankObject extends React.Component {
    constructor(props) { super(props) }

    static bankAccountList = [
        {
            key: 'BAY',
            text: 'ธนาคารกรุงศรีอยุธยา (BAY)',
            value: 'BAY',
            image: { avatar: true, src: bay_img },
        },
        {
            key: 'KTB',
            text: 'ธนาคารกรุงไทย (KTB)',
            value: 'KTB',
            image: { avatar: true, src: ktb_img },
        },
        {
            key: 'KBNK',
            text: 'ธนาคารกสิกรไทย (KBNK)',
            value: 'KBNK',
            image: { avatar: true, src: kbnk_img },
        },
        {
            key: 'BBL',
            text: 'ธนาคารกรุงเทพ (BBL)',
            value: 'BBL',
            image: { avatar: true, src: bbl_img },
        },
        // {
        //     key: 'TMB',
        //     text: 'ธนาคารทหารไทย (TMB)',
        //     value: 'TMB',
        //     image: { avatar: true, src: tmb_img },
        // },
        {
            key: 'GSB',
            text: 'ธนาคารออมสิน (GSB)',
            value: 'GSB',
            image: { avatar: true, src: gsb_img },
        },
        // {
        //     key: 'TBNK',
        //     text: 'ธนาคารธนชาต (TBNK)',
        //     value: 'TBNK',
        //     image: { avatar: true, src: tbnk_img },
        // },
        {
            key: 'BAAC',
            text: 'ธนาคารเพื่อการเกษตรและสหกรณ์ (BAAC)',
            value: 'BAAC',
            image: { avatar: true, src: baac_img },
        },
        {
            key: 'SCB',
            text: 'ธนาคารไทยพาณิชย์ (SCB)',
            value: 'SCB',
            image: { avatar: true, src: scb_img },
        },
        {
            key: 'KKP',
            text: 'ธนาคารเกียรตินาคิน (KKP)',
            value: 'KKP',
            image: { avatar: true, src: kkba_img },
        },
        {
            key: 'GHB',
            text: 'ธนาคารอาคารสงเคราะห์ (GHB)',
            value: 'GHB',
            image: { avatar: true, src: ghba_img },
        },
        {
            key: 'TTB',
            text: 'ธนาคารทหารไทยธนชาต (TTB)',
            value: 'TTB',
            image: { avatar: true, src: ttb_img },
        },
    ]

    static getBankData(bankName = UserService.getBankAccount().bankName) {
        return this.bankAccountList.find(elm => elm.key == bankName)
    }

    static SettingBankAccount = (props) => <SettingBankAccount {...props} />
}
export default Setting
export { SettingBankAccount, BankObject }

