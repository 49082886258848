import React, { Component } from 'react'
import { Grid, Table, Button, Input, Icon, Segment, Message, Header, Container, Image, Dropdown, Modal } from 'semantic-ui-react';
import UserService from '../../../service/UserService'
import copy from 'copy-to-clipboard';
import { FacebookShareButton, LineShareButton } from "react-share";
import QRCode from 'qrcode.react'
import { AFIcon } from '../../../assets/images/svg/svg-icon'
import ConfigValueService from '../../../service/ConfigValueService';
import WalletService from '../../../service/WalletService';
import { ToastContainer, Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../../config/config';

class Invite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteLink: 'https://' + window.location.hostname + '/register/invite/' + UserService.getUser()._id,
            invitedCount: UserService.getUser().invitedCount,
            visitedCount: UserService.getUser().visitedCount,
            sumChildrenLevel1Topup: 0,
            childrenList: [],
            copyStatus: null,
            totalPriceOfFilter: {
                topup: 0,
                play: 0,
                afIncome: 0
            },
            filterDate: 60,
            expandIndex: -1,
            afInfo: {},
            isLoadingAFInfo: false,
            countUserDetail: {},
            AFSelect: 'ยอดแทงของสมาชิก',
            isModalAF: false,
            minimumAFWitdraw: 1,
            AFWithdrawVelue: "",
            AfWithdrawLoading: false,
            AFcurrent: Number(WalletService.getWalletBalance().af) || 0,
            showChildrenDetail: false,
            isLoadChildrenDetail: false,
            afSumGroupBydate: [],
            modalSearchChildren: false,
            searchValue: '',
            searchChildrenData: [],
            isLoadingSearchChildren: false,
            searchChildrenDataSucess: false
        };
    }

    async componentDidMount() {
        this.props.handelChangeLoadingPane(true)
        // await UserService.getInviteLink().then(res => {
        //     if (res.status) {
        //         this.setState({ inviteLink: res.data.link })
        //     } else {
        //         this.setState({ inviteLink: '' })
        //     }
        // })

        await ConfigValueService.MINIMUM_WITHDRAW_AF().then(res => {
            this.setState({ minimumAFWitdraw: res.data.value || 1 })
        })
        this.props.handelChangeLoadingPane(false)
    }

    getAfInfo = async () => {
        this.setState({ isLoadingAFInfo: true })
        await UserService.getAFInfo().then(async res => {
            if (res.status) {
                this.setState({
                    afInfo: res.data,
                    AFcurrent: res.data.AFcurrent,
                    isLoadingAFInfo: false
                });
            }
        })
    }

    getChildrenDetail = async () => {
        await this.setState({ isLoadChildrenDetail: true })
        await UserService.getAFInfoStat().then(async res => {
            if (res.status) {
                let lv1 = []
                let lv2 = []
                await res.data.countUserL1ByDate?.map((item, index) => {
                    lv1.push({
                        date: item.date,
                        count_lv1: item.const
                    })
                })
                await res.data.countUserL2ByDate?.map((item, index) => {
                    lv2.push({
                        date: item.date,
                        count_lv2: item.const
                    })
                })
                let countUserSort = await [...lv1, ...lv2].sort(function (a, b) {
                    return new Date(b.date) - new Date(a.date);
                })
                let results = countUserSort.reduce(function (results, org) {
                    (results[org.date] = results[org.date] || []).push(org);
                    return results;
                }, {})
                this.setState({
                    afSumGroupBydate: res.data.afSumGroupBydate,
                    countUserDetail: results,
                    isLoadChildrenDetail: false,
                    showChildrenDetail: true
                })
            }
        })

    }

    initData() {
        this.sumChildrenLevel1Topup()
        this.filterData()
    }

    sumChildrenLevel1Topup = () => {
        let sumPrice = 0
        const childrens = this.state.childrenList
        childrens.forEach(childrenItem => {
            childrenItem.wallet.creditHistory.forEach(item => {
                if (item.type == 1) {
                    sumPrice += Number(Number(item.actionValue).toFixed(2))
                }
            })
        });
        this.setState({ sumChildrenLevel1Topup: sumPrice })
    }

    filterData() {
        // cal sumTopupPrice for each
        let totalPrice = {
            topup: 0,
            play: 0,
            afIncome: 0
        }
        this.state.childrenList.forEach((childrenItem, key) => {
            let creditHistoryFilter = this.filterTransection(childrenItem.wallet.creditHistory, this.state.filterDate)
            totalPrice.afIncome += childrenItem.sumAFOutcome ? (childrenItem.sumAFOutcome.L1 || 0) : 0
            creditHistoryFilter.forEach(transItem => {
                if (transItem.type == 1) {
                    totalPrice.topup += Number(Number(transItem.actionValue).toFixed(2))
                } else if (transItem.type == 3) {
                    totalPrice.play += Number(Number(transItem.actionValue).toFixed(2))
                }
            });
            //cal childen level 2
            if (childrenItem.children) {
                childrenItem.children.forEach((childrenItemL2, key) => {
                    totalPrice.afIncome += childrenItemL2.sumAFOutcome ? (childrenItemL2.sumAFOutcome.L2 || 0) : 0
                    let creditHistoryFilter = this.filterTransection(childrenItemL2.wallet.creditHistory, this.state.filterDate)
                    creditHistoryFilter.forEach(transItem => {
                        if (transItem.type == 1) {
                            totalPrice.topup += Number(Number(transItem.actionValue).toFixed(2))
                        } else if (transItem.type == 3) {
                            totalPrice.play += Number(Number(transItem.actionValue).toFixed(2))
                        }
                    });
                })
            }
        })
        this.setState({ totalPriceOfFilter: totalPrice })
    }

    filterTransection(transList = [], minusValue) {
        if (transList.length == 0) { return [] }
        var nowDate = new Date();
        nowDate.setDate(nowDate.getDate() - parseInt(minusValue))
        return transList.filter(item => new Date(item.createAt) >= nowDate)
    }

    handelCopyInviteLink() {
        copy(this.state.inviteLink)
        this.setState({ copyStatus: "คัดลอก" })
        setTimeout(() => {
            this.setState({ copyStatus: null })
        }, 500)
    }

    handleChangeMoney = (event) => {
        const minimumAFWitdraw = this.state.minimumAFWitdraw
        this.setState({ next: true })
        let value = event.target.value.replace(/,/g, '');
        value = Number(value)
        if (!isNaN(value)) {
            if (value <= Number(this.state.AFcurrent)) {
                if (value >= minimumAFWitdraw) {
                    this.setState({ next: false })
                }
                // let valueReplace = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");
                this.setState({ AFWithdrawVelue: value })
            }
        }
    };

    AFWithdraw = () => {
        let _this = this;
        this.setState({ AfWithdrawLoading: true, errorMSG: "" });
        setTimeout(function () {
            WalletService.AFwithdraw(Number(_this.state.AFWithdrawVelue)).then(res => {
                if (res.status) {
                    _this.setState({ AfWithdrawLoading: false });
                    WalletService.fetchWalletBalance();
                    _this.setState({ AFcurrent: WalletService.getWalletBalance().af });
                    _this.setState({ AFWithdrawVelue: '' });
                    _this.setState({ isModalAF: false });
                    toast.success("โอน AF สำเร็จ", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else {
                    _this.setState({ errorMSG: res.msg })
                    _this.setState({ AfWithdrawLoading: false });
                    toast.error("โอน AF ไม่สำเร็จ", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
        }, 1000);
    }

    getChildren = () => {
        this.setState({ isLoadingSearchChildren: true })
        UserService.getSearchChildren(this.state.searchValue).then((res) => {
            if (res.status) {
                this.setState({ searchChildrenData: res.data, searchChildrenDataSucess: true, isLoadingSearchChildren: false })
            } else {
                this.setState({ isLoadingSearchChildren: false })
            }
        })
    }

    handleSearchChildrenChange = (e) => {
        if (e.target.value.toString().length <= 10) {
            this.setState({ searchValue: e.target.value.toString() })
        }
    }

    toTwoDecimal = (num) => {
        let num_new = (parseInt(Number(Number((Number(num) * 10).toFixed(3)) * 10).toFixed(3)) / 100.00)
        let _num = num_new.toString().split('.')
        if (_num.length > 1) {
            return (_num[0] + '.' + (_num[1].length < 2 ? `${_num[1].substring(0, 2)}0` : _num[1].substring(0, 2))).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } else {
            return (_num[0] + '.00').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }

    render() {
        return (
            <Container className="profileCustom">
                <Grid centered>
                    {/* <Grid.Column width='16' style={{ paddingTop: 3, paddingBottom: 3 }}>
                        <Dropdown style={{ border: 0, boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.1)', borderRadius: 10 }} placeholder={`${this.state.filterDate} วัน`} style={{ textAlign: 'center', borderRadius: 7 }} fluid selection options={[{ key: '1', text: 'ดูข้อมูลย้อนหลัง 60 วัน', value: 60 }, { key: '2', text: 'ดูข้อมูลย้อนหลัง 30 วัน', value: 30 }, { key: '3', text: 'ดูข้อมูลย้อนหลัง 14 วัน', value: 14 }, { key: '4', text: 'ดูข้อมูลย้อนหลัง 7 วัน', value: 7 }, { key: '5', text: 'ดูข้อมูลย้อนหลัง 1 วัน', value: 1 }]} value={this.state.filterDate} onChange={(event, data) => { this.setState({ filterDate: data.value }, () => this.filterData()) }} />
                    </Grid.Column> */}
                    {/* <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment style={{ display: 'flex', border: 0, boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.1)', borderRadius: 10 }}>
                            <Container className='aligned' style={{ flex: 3, paddingLeft: 20, marginTop: 5, marghinBottom: 5 }}>
                                <Header style={{ fontSize: '2em', fontWeight: 500, color: '#444', margin: 0 }}> {Number(this.state.invitedCount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </Header>
                                <span style={{ fontSize: '1em', fontWeight: 300, color: '#AAA' }}>ยอดสมาชิก</span>
                            </Container>
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                <Icon name='users' size='big' color='blue' />
                            </div>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment style={{ display: 'flex', border: 0, boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.1)', borderRadius: 10 }}>
                            <Container className='aligned' style={{ flex: 3, paddingLeft: 20, marginTop: 5, marghinBottom: 5 }}>
                                <Header style={{ fontSize: '2em', fontWeight: 500, color: '#444', margin: 0 }}> {Number(this.state.totalPriceOfFilter.afIncome).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </Header>
                                <span style={{ fontSize: '1em', fontWeight: 300, color: '#AAA' }}>รายได้สะสม</span>
                            </Container>
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                <svg width="60" height="60" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="50.5" cy="50.5" r="50.5" fill="#00B261" fillOpacity="0.1" />
                                    <path d="M31 65.5556L31 45.5556C31 43.101 32.9898 41.1111 35.4444 41.1111L44.3333 41.1111L44.3333 45.5556L35.4444 45.5556L35.4444 65.5556L66.5556 65.5556L66.5556 45.5556L57.6667 45.5556L57.6667 41.1111L66.5556 41.1111C69.0102 41.1111 71 43.101 71 45.5556L71 65.5556C71 68.0102 69.0102 70 66.5556 70L35.4444 70C32.9898 70 31 68.0102 31 65.5556ZM42.1111 50L48.7778 50L48.7778 30L53.2222 30L53.2222 50L59.8889 50L51 61.1111L42.1111 50Z" fill="#00B261" />
                                </svg>
                            </div>
                        </Segment>
                    </Grid.Column> */}
                    <Grid.Column mobile={16} tablet={16} computer={16} style={{ paddingTop: 4, paddingBottom: 4 }} >
                        <Segment className="card-af-user">
                            <div style={{ display: 'flex', paddingBottom: 5, paddingLeft: 5 }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                                    <img src={require('../../../assets/images/af-2.png')} width={60} height={60} />
                                </div>
                                <div style={{ paddingLeft: 5, marginTop: 5, marginBottom: 5 }}>
                                    <Header style={{ fontSize: '1.8em', fontWeight: 500, color: '#FFF', margin: 0 }}> {this.toTwoDecimal(this.state.AFcurrent)} </Header>
                                    <span style={{ fontSize: '1em', fontWeight: 300, color: '#FFF' }}>ยอดAFปัจจุบัน</span>
                                </div>
                            </div>
                            <Button
                                fluid
                                size='medium'
                                style={{
                                    borderRadius: 30,
                                    background: '#FFF',
                                    color: '#000',
                                    fontSize: '1.3em',
                                    border: '2px solid #FEC3FF',
                                    padding: '8px 0'
                                }}
                                onClick={() => this.setState({ isModalAF: true })}
                            >
                                <span style={{ fontWeight: 500 }}>ถอน</span>
                            </Button>
                        </Segment>
                        <Modal
                            open={this.state.isModalAF}
                            onClose={() => this.setState({ isModalAF: false, AFWithdrawVelue: '' })}
                            closeIcon
                        >
                            <Segment style={{ marginTop: 5, border: 0, padding: '30px 10px' }} loading={this.state.AfWithdrawLoading}>
                                <center style={{ width: '100%', textAlign: 'center', }}>
                                    <div style={{}}>
                                        <p style={{ fontWeight: 400, fontSize: 14 }}>จำนวน AF ที่ต้องการโอน</p>
                                        <p style={{ color: 'red' }}>{this.state.errorMSG}</p>
                                        <div style={{ display: 'flex', justifyContent: "center" }}>
                                            <div style={{ background: '#F8F8F8', padding: 20, borderRadius: 15, display: 'flex', alignItems: 'center', width: '50%' }}>
                                                <div style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                                                    {/* <span style={{ color: '#BDBDBD', marginRight: 20 }}>จาก</span> */}
                                                    <AFIcon width='25' height='25' />
                                                </div>
                                                <Input placeholder='0' type='text' style={{ maxWidth: '80%' }} >
                                                    <input style={{ textAlign: "right" }} value={this.state.AFWithdrawVelue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} onChange={(event) => this.handleChangeMoney(event)} />
                                                </Input>
                                            </div>
                                        </div>
                                    </div>
                                </center>
                                <center style={{ color: '#FF7A00', fontSize: 16, marginTop: 20, marginBottom: 5 }}>โอนขั้นต่ำครั้งละ {this.state.minimumAFWitdraw}AF</center>
                                <center style={{ color: '#FF0000', marginBottom: 10 }}>
                                    * หมายเหตุ ยอด AF ที่ถอนจะเข้าเป็นยอดเงินในกระเป๋าหลัก
                                </center>
                                <center>
                                    <Button style={{ background: '#00659E', color: '#FFFFFF', width: '50%' }} onClick={() => this.AFWithdraw()}>ยืนยัน</Button>
                                </center>
                            </Segment>
                        </Modal>
                    </Grid.Column>

                    <Button
                        fluid
                        style={{
                            borderRadius: 15,
                            background: 'var(--background-button-invite)',
                            marginLeft: '1rem',
                            marginRight: '1rem',
                            color: '#FFF',
                            padding: '15px 0',
                            fontSize: '1.3em',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        onClick={() => this.setState({ modalSearchChildren: true })}
                    >
                        <Icon name="search" />
                        กดค้นหาสมาชิก
                    </Button>

                    <Modal
                        open={this.state.modalSearchChildren}
                        closeIcon
                        onClose={() => this.setState({
                            modalSearchChildren: false,
                            searchValue: '',
                            searchChildrenData: [],
                            isLoadingSearchChildren: false,
                            searchChildrenDataSucess: false
                        })}
                    >
                        <Modal.Header>
                            ค้นหา ID สมาชิก
                        </Modal.Header>
                        <Modal.Content>
                            <div>
                                <center>
                                    <Input placeholder="กรอก ID สมาชิก" onChange={(e) => this.handleSearchChildrenChange(e)} value={this.state.searchValue} />
                                    {
                                        (!!this.state.searchValue && this.state.searchValue.length < 10) &&
                                        <div style={{ color: 'red' }}> * กรอก ID สมาชิก ให้ครบ 10 ตัว * </div>
                                    }
                                </center>


                                <center>
                                    <Button
                                        style={{
                                            borderRadius: 15,
                                            background: '#21BA45',
                                            color: '#FFF',
                                            padding: '10px 30px',
                                            marginTop: 10
                                        }}
                                        onClick={this.getChildren}
                                        disabled={this.state.searchValue.length == 10 ? false : true}
                                        loading={this.state.isLoadingSearchChildren}
                                    >ค้นหา</Button>
                                </center>

                                {
                                    this.state.searchChildrenDataSucess &&
                                    <div>
                                        <Table unstackable
                                            style={{
                                                background: 'var(--background-button-invite)',
                                                paddingTop: 3,
                                                margin: 0,
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                boxShadow: 'rgba(34, 36, 38, 0.1) 0px 2px 4px 0px',
                                                marginTop: 10
                                            }}>
                                            <Table.Body style={{}}>
                                                <Table.Row style={{ background: 'var(--background-button-invite)', color: '#FFF' }}>
                                                    <Table.Cell width="2" style={{ padding: '0.5em  0em', textAlign: 'center' }}>วันที่สมัคร</Table.Cell>
                                                    <Table.Cell width="2" style={{ padding: '0.5em  0em', textAlign: 'center' }}>ยอดเล่น</Table.Cell>
                                                    <Table.Cell width="2" style={{ padding: '0.5em  0em', textAlign: 'center' }}>ยอดAFที่ได้จากคนนี้</Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                            <Table.Body style={{}}>
                                                {
                                                    this.state.searchChildrenData?.map((item, key) => (<>
                                                        <Table.Row key={key} style={{ backgroundColor: '#FFF', color: '#888' }}>
                                                            <Table.Cell width="2" style={{ padding: '0.5em 0em', textAlign: 'center' }}>{new Date(item.registerDate).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })}</Table.Cell>
                                                            <Table.Cell width="3" style={{ padding: '0.5em  0em', textAlign: 'center' }}>{Number(item.totalPlay).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Table.Cell>
                                                            <Table.Cell width="1" style={{ padding: '0.5em  0em', textAlign: 'center' }}>{Number(item.reciveAf).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Table.Cell>
                                                        </Table.Row>
                                                    </>
                                                    ))}
                                            </Table.Body>
                                        </Table>
                                        {
                                            this.state.searchChildrenData?.length === 0 &&
                                            <center style={{ width: '100%' }}><Message info content='ไม่พบสมาชิก' style={{ textAlign: 'center', borderRadius: '0 0 0.28571429rem 0.28571429rem' }} /></center>
                                        }
                                    </div>
                                }

                            </div>

                        </Modal.Content>

                    </Modal>

                    <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment loading={this.state.isLoadingAFInfo} className="card-af-user-2">
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                <img src={require('../../../assets/images/child-1.png')} width={60} height={60} />
                            </div>
                            <div className='aligned' style={{ flex: 3, marginTop: 5, marginBottom: 10, cursor: "pointer" }} onClick={() => this.getAfInfo()}>
                                <div style={{ fontSize: '2em', fontWeight: 500, color: '#000', margin: 0 }}>
                                    {this.state.afInfo.numberOfChildrenLevel1 == undefined ? <h3 style={{ marginBottom: 10, marginTop: 10 }}>คลิกเพื่อดูข้อมูล</h3>
                                        : Number(this.state.afInfo.numberOfChildrenLevel1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                                <span style={{ fontSize: '1em', fontWeight: 300, color: '#000' }}>ยอดสมาชิกขั้นที่ 1</span>
                            </div>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment loading={this.state.isLoadingAFInfo} className="card-af-user-2" onClick={() => this.getAfInfo()}>
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                <img src={require('../../../assets/images/child-2.png')} width={60} height={60} />
                            </div>
                            <div className='aligned' style={{ flex: 3, marginTop: 5, marginBottom: 10, cursor: "pointer" }} onClick={() => this.getAfInfo()}>
                                <div style={{ fontSize: '2em', fontWeight: 500, color: '#000', margin: 0 }}>
                                    {this.state.afInfo.numberOfChildrenLevel2 == undefined ? <h3 style={{ marginBottom: 10, marginTop: 10 }}>คลิกเพื่อดูข้อมูล</h3>
                                        : Number(this.state.afInfo.numberOfChildrenLevel2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                                <span style={{ fontSize: '1em', fontWeight: 300, color: '#000' }}>ยอดสมาชิกขั้นที่ 2</span>
                            </div>

                        </Segment>
                    </Grid.Column>
                    {/* <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment style={{ display: 'flex', border: 0, boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.1)', borderRadius: 10 }}>
                            <Container className='aligned' style={{ flex: 3, paddingLeft: 20, marginTop: 5, marghinBottom: 5 }}>
                                <Header style={{ fontSize: '2em', fontWeight: 500, color: '#444', margin: 0 }}> {Number(this.state.afInfo.AFsumTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </Header>
                                <span style={{ fontSize: '1em', fontWeight: 300, color: '#AAA' }}>รายได้สะสม</span>
                            </Container>
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                <img src={require('../../../assets/images/sum-af.png')} width={60} height={60} />
                            </div>
                        </Segment>
                    </Grid.Column> */}
                    {/* <Grid.Column mobile={16} tablet={16} computer={16} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment style={{ display: 'flex', border: 0, boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.1)', borderRadius: 10 }}>
                            <Container className='aligned' style={{ flex: 3, paddingLeft: 20, marginTop: 5, marghinBottom: 5 }}>
                                <Header style={{ fontSize: '2em', fontWeight: 500, color: '#444', margin: 0 }}> {Number(this.state.afInfo.AFcurrent).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </Header>
                                <span style={{ fontSize: '1em', fontWeight: 300, color: '#AAA' }}>ยอดAFปัจจุบัน</span>
                            </Container>
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                <img src={require('../../../assets/images/af.png')} width={60} height={60} />
                            </div>
                        </Segment>
                    </Grid.Column> */}

                    {/* <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment style={{ display: 'flex', border: 0, boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.1)', borderRadius: 10 }}>
                            <Container className='aligned' style={{ flex: 3, paddingLeft: 20, marginTop: 5, marghinBottom: 5 }}>
                                <Header style={{ fontSize: '2em', fontWeight: 500, color: '#444', margin: 0 }}> {Number(this.state.totalPriceOfFilter.topup).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </Header>
                                <span style={{ fontSize: '1em', fontWeight: 300, color: '#AAA' }}>ยอดเติมในสาย</span>
                            </Container>
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                <svg width="60" height="60" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="50.5" cy="50.5" r="50.5" fill="#00B261" fillOpacity="0.1" />
                                    <path d="M31 65.5556L31 45.5556C31 43.101 32.9898 41.1111 35.4444 41.1111L44.3333 41.1111L44.3333 45.5556L35.4444 45.5556L35.4444 65.5556L66.5556 65.5556L66.5556 45.5556L57.6667 45.5556L57.6667 41.1111L66.5556 41.1111C69.0102 41.1111 71 43.101 71 45.5556L71 65.5556C71 68.0102 69.0102 70 66.5556 70L35.4444 70C32.9898 70 31 68.0102 31 65.5556ZM42.1111 50L48.7778 50L48.7778 30L53.2222 30L53.2222 50L59.8889 50L51 61.1111L42.1111 50Z" fill="#00B261" />
                                </svg>
                            </div>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment style={{ display: 'flex', border: 0, boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.1)', borderRadius: 10 }}>
                            <Container className='aligned' style={{ flex: 3, paddingLeft: 20, marginTop: 5, marghinBottom: 5 }}>
                                <Header style={{ fontSize: '2em', fontWeight: 500, color: '#444', margin: 0 }}> {Number(this.state.totalPriceOfFilter.play).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </Header>
                                <span style={{ fontSize: '1em', fontWeight: 300, color: '#AAA' }}>ยอดแทงในสาย</span>
                            </Container>
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                <svg width="60" height="60" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="50.5" cy="50.5" r="50.5" fill="#F4B74A" fillOpacity="0.1" />
                                    <path d="M65.4584 37.6496V33.8766C66.5242 33.2552 67.25 32.1124 67.25 30.7916V29.8959C67.25 29.4007 66.8493 29 66.3542 29H35.8959C35.4007 29 35 29.4007 35 29.8959V30.7917C35 32.1124 35.7258 33.2552 36.7916 33.8767V37.6496C36.7916 41.1997 38.3051 44.5975 40.9437 46.9718L44.8638 50.5L40.9437 54.0282C38.3051 56.4025 36.7916 59.8004 36.7916 63.3505V67.1233C35.7258 67.7448 35 68.8876 35 70.2084V71.1042C35 71.5993 35.4007 72 35.8959 72H66.3542C66.8494 72 67.2501 71.5993 67.2501 71.1042V70.2083C67.2501 68.8876 66.5243 67.7448 65.4585 67.1233V63.3505C65.4585 59.8004 63.945 56.4025 61.3064 54.0282L57.3862 50.5L61.3064 46.9718C63.9449 44.5975 65.4584 41.1996 65.4584 37.6496ZM58.9094 44.3088L53.5098 49.1685C53.1319 49.508 52.9166 49.9917 52.9166 50.5C52.9166 51.0083 53.1319 51.4921 53.5098 51.8315L58.9094 56.6913C60.7937 58.3876 61.875 60.8152 61.875 63.3505V66.625H59.635L51.8415 56.2337C51.5038 55.7823 50.7462 55.7823 50.4085 56.2337L42.615 66.625H40.375V63.3505C40.375 60.8151 41.4563 58.3875 43.3407 56.6912L48.7403 51.8314C49.1182 51.4919 49.3335 51.0082 49.3335 50.4999C49.3335 49.9917 49.1182 49.5078 48.7403 49.1684L43.3407 44.3087C41.4563 42.6124 40.375 40.1848 40.375 37.6496V34.375H61.875V37.6496C61.875 40.1848 60.7937 42.6124 58.9094 44.3088Z" fill="#F4B74A" />
                                    <path d="M57.2995 41.5416H44.9494C44.5951 41.5416 44.274 41.7507 44.1306 42.0744C43.9871 42.399 44.0474 42.777 44.2863 43.0394L50.5239 48.7941C50.6945 48.949 50.9097 49.0259 51.1249 49.0259C51.3401 49.0259 51.5553 48.9489 51.7259 48.7941L57.9626 43.0394C58.2014 42.777 58.2618 42.399 58.1183 42.0744C57.9749 41.7507 57.6538 41.5416 57.2995 41.5416Z" fill="#F4B74A" />
                                </svg>
                            </div>
                        </Segment>
                    </Grid.Column> */}

                    <Grid.Column mobile={16} tablet={16} computer={16} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <Segment style={{
                            border: '4px solid var(--background-head-menu)',
                            boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.1)',
                            padding: 20,
                            borderRadius: 20,
                            textAlign: 'center'
                        }}>
                            <p>ลิงค์ชวนเพื่อน
                                {/* <span style={{ color: '#AAA' }}>( {this.state.visitedCount} คลิก )</span> */}
                            </p>
                            <Input style={{ 'width': '100%' }}
                                size='mini'
                                action={{
                                    color: 'red',
                                    icon: 'copy',
                                    content: this.state.copyStatus,
                                    onClick: () => this.handelCopyInviteLink(),
                                }}
                                readOnly
                                defaultValue={this.state.inviteLink}
                            />
                            <center style={{ marginTop: 20, marginBottom: 10 }}>
                                <QRCode value={this.state.inviteLink} />,
                            </center>
                            <center>
                                <p style={{ color: '#AAA', fontSize: 13, fontWeight: 300, paddingLeft: 5 }}>แชร์ให้เพื่อน</p>
                                <FacebookShareButton url={this.state.inviteLink} >
                                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M33.25 0H4.75C2.12958 0 0 2.12958 0 4.75V33.25C0 35.8688 2.12958 38 4.75 38H33.25C35.8688 38 38 35.8688 38 33.25V4.75C38 2.12958 35.8688 0 33.25 0Z" fill="#3B5999" />
                                        <path d="M26.125 19V14.25C26.125 12.939 27.189 13.0625 28.5 13.0625H30.875V7.125H26.125C22.1888 7.125 19 10.3138 19 14.25V19H14.25V24.9375H19V38H26.125V24.9375H29.6875L32.0625 19H26.125Z" fill="white" />
                                    </svg>
                                </FacebookShareButton>
                                <LineShareButton url={this.state.inviteLink} style={{ marginLeft: 10 }}>
                                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M34 0H4C1.79086 0 0 1.79086 0 4V34C0 36.2091 1.79086 38 4 38H34C36.2091 38 38 36.2091 38 34V4C38 1.79086 36.2091 0 34 0Z" fill="#00C200" />
                                        <path d="M32.7915 18.3112C32.8554 17.9747 32.887 17.6917 32.9015 17.4696C32.9255 17.1031 32.8981 16.5591 32.8884 16.3887C32.5508 10.5179 26.4599 5.84058 18.9955 5.84058C11.3145 5.84058 5.08789 10.7932 5.08789 16.9027C5.08789 22.5182 10.3483 27.1562 17.1634 27.8697C17.5801 27.9133 17.8805 28.2894 17.8343 28.7057L17.5442 31.3174C17.4783 31.9103 18.0913 32.3416 18.6285 32.0823C24.3864 29.3035 27.8295 26.4447 29.8855 23.968C30.2597 23.5173 31.4796 21.8081 31.7284 21.3592C32.2563 20.4061 32.6203 19.3827 32.7915 18.3112Z" fill="white" />
                                        <path d="M11.3476 19.1436V14.484C11.3476 14.0911 11.0291 13.7726 10.6362 13.7726C10.2433 13.7726 9.9248 14.0911 9.9248 14.484V19.855C9.9248 20.2479 10.2433 20.5664 10.6362 20.5664H13.4818C13.8747 20.5664 14.1932 20.2479 14.1932 19.855C14.1932 19.4621 13.8747 19.1436 13.4818 19.1436H11.3476Z" fill="#00C500" />
                                        <path d="M15.7361 20.5664H15.4251C15.118 20.5664 14.8691 20.3175 14.8691 20.0105V14.3285C14.8691 14.0215 15.118 13.7726 15.4251 13.7726H15.7361C16.0431 13.7726 16.292 14.0215 16.292 14.3285V20.0105C16.292 20.3175 16.0431 20.5664 15.7361 20.5664Z" fill="#00C500" />
                                        <path d="M21.4854 14.484V17.7652C21.4854 17.7652 18.6443 14.0594 18.6009 14.0107C18.4652 13.8584 18.2652 13.7647 18.0431 13.7731C17.6561 13.7878 17.3594 14.126 17.3594 14.5132V19.855C17.3594 20.2478 17.6779 20.5664 18.0708 20.5664C18.4637 20.5664 18.7822 20.2478 18.7822 19.855V16.5937C18.7822 16.5937 21.6665 20.3317 21.7089 20.3719C21.8347 20.491 22.0037 20.5647 22.1902 20.5664C22.5853 20.57 22.9082 20.2205 22.9082 19.8254V14.484C22.9082 14.0911 22.5897 13.7726 22.1968 13.7726C21.8039 13.7727 21.4854 14.0911 21.4854 14.484Z" fill="#00C500" />
                                        <path d="M28.244 14.484C28.244 14.0911 27.9255 13.7726 27.5326 13.7726H24.687C24.2941 13.7726 23.9756 14.0911 23.9756 14.484V19.855C23.9756 20.2479 24.2941 20.5664 24.687 20.5664H27.5326C27.9255 20.5664 28.244 20.2479 28.244 19.855C28.244 19.4621 27.9255 19.1436 27.5326 19.1436H25.3984V17.8808H27.5326C27.9255 17.8808 28.244 17.5623 28.244 17.1694C28.244 16.7765 27.9255 16.458 27.5326 16.458H25.3984V15.1953H27.5326C27.9255 15.1954 28.244 14.8769 28.244 14.484Z" fill="#00C500" />
                                    </svg>
                                </LineShareButton>
                            </center>
                        </Segment>
                    </Grid.Column>
                    {
                        this.state.showChildrenDetail ?
                            <>
                                <Dropdown
                                    options={
                                        [
                                            {
                                                key: 'ยอดแทงของสมาชิก',
                                                text: 'ยอดแทงของสมาชิก',
                                                value: 'ยอดแทงของสมาชิก',
                                            },
                                            {
                                                key: 'ยอดจำนวนของสมาชิก',
                                                text: 'ยอดจำนวนของสมาชิก',
                                                value: 'ยอดจำนวนของสมาชิก',
                                            }
                                        ]
                                    }
                                    defaultValue={'ยอดแทงของสมาชิก'}
                                    onChange={(event, data) => {
                                        this.setState({ AFSelect: data.value })
                                    }}
                                    className={"AFInfoDropdown"}
                                />
                                {
                                    this.state.AFSelect === 'ยอดแทงของสมาชิก' &&
                                    <Grid.Column mobile={16} tablet={16} computer={16} style={{ paddingTop: 4, paddingBottom: 4, marginBottom: 0 }}>
                                        <div>
                                            <Table unstackable
                                                style={{
                                                    background: 'var(--background-button-invite)',
                                                    paddingTop: 3,
                                                    margin: 0,
                                                    paddingLeft: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    boxShadow: 'rgba(34, 36, 38, 0.1) 0px 2px 4px 0px',
                                                }}>
                                                <Table.Body style={{}}>
                                                    <Table.Row style={{ background: 'var(--background-button-invite)', color: '#FFF' }}>
                                                        <Table.Cell width="2" style={{ padding: '0.5em  0em', textAlign: 'center' }}>วันที่</Table.Cell>
                                                        <Table.Cell width="3" style={{ padding: '0.5em  0em', textAlign: 'center' }}>ยอดแทงสมาชิก</Table.Cell>
                                                        <Table.Cell width="1" style={{ padding: '0.5em  0em', textAlign: 'center' }}>AF</Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                                <Table.Body style={{}}>
                                                    {
                                                        this.state.afSumGroupBydate?.map((item, key) => (<>
                                                            <Table.Row key={key} style={{ backgroundColor: '#FFF', color: '#888' }}>
                                                                <Table.Cell width="2" style={{ padding: '0.5em 0em', textAlign: 'center' }}>{new Date(item.date).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })}</Table.Cell>
                                                                <Table.Cell width="3" style={{ padding: '0.5em  0em', textAlign: 'center' }}>{Number(item.sumPlayPrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Table.Cell>
                                                                <Table.Cell width="1" style={{ padding: '0.5em  0em', textAlign: 'center' }}>{Number(item.totalAFincom).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Table.Cell>
                                                            </Table.Row>
                                                        </>
                                                        ))}
                                                </Table.Body>
                                            </Table>
                                            {
                                                this.state.afSumGroupBydate?.length === 0 &&
                                                <center style={{ width: '100%' }}><Message info content='ไม่มียอดแทงของสมาชิก' style={{ textAlign: 'center', borderRadius: '0 0 0.28571429rem 0.28571429rem' }} /></center>
                                            }
                                        </div>
                                    </Grid.Column>
                                }
                                {
                                    this.state.AFSelect === 'ยอดจำนวนของสมาชิก' &&
                                    <Grid.Column mobile={16} tablet={16} computer={16} style={{ paddingTop: 4, paddingBottom: 4, marginBottom: 0 }}>
                                        <div>
                                            <Table unstackable style={{ background: 'var(--background-button-invite)', paddingTop: 3, margin: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 0, border: 0, boxShadow: 'rgba(34, 36, 38, 0.1) 0px 2px 4px 0px' }}>
                                                <Table.Body style={{}}>
                                                    <Table.Row style={{ background: 'var(--background-button-invite)', color: '#FFF' }}>
                                                        <Table.Cell width="2" style={{ padding: '0.5em  0.1em', textAlign: 'center' }}>วันที่</Table.Cell>
                                                        <Table.Cell width="2" style={{ padding: '0.5em  0.1em', textAlign: 'center' }}>ยอดสมาชิกขั้นที่ 1</Table.Cell>
                                                        <Table.Cell width="2" style={{ padding: '0.5em  0.1em', textAlign: 'center' }}>ยอดสมาชิกขั้นที่ 2</Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                                <Table.Body style={{}}>
                                                    {
                                                        Object.entries(this.state.countUserDetail).map((item, index) => {
                                                            if (item['1'].length == 2) {
                                                                return <Table.Row key={index} style={{ backgroundColor: '#FFF', color: '#888' }}>
                                                                    <Table.Cell width="2" style={{ padding: '0.5em 0.1em', textAlign: 'center' }}>{new Date(item['1']['0'].date).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })}</Table.Cell>
                                                                    <Table.Cell width="2" style={{ padding: '0.5em 0.1em', textAlign: 'center' }}>{item['1']['0']['count_lv1']}</Table.Cell>
                                                                    <Table.Cell width="2" style={{ padding: '0.5em 0.1em', textAlign: 'center' }}>{item['1']['1']['count_lv2']}</Table.Cell>
                                                                </Table.Row>
                                                            } else {
                                                                return <Table.Row key={index} style={{ backgroundColor: '#FFF', color: '#888' }}>
                                                                    <Table.Cell width="2" style={{ padding: '0.5em 0em', textAlign: 'center' }}>{new Date(item['1']['0'].date).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })}</Table.Cell>
                                                                    <Table.Cell width="2" style={{ padding: '0.5em  0em', textAlign: 'center' }}>{item['1']['0']['count_lv1'] || 0}</Table.Cell>
                                                                    <Table.Cell width="2" style={{ padding: '0.5em  0em', textAlign: 'center' }}>{item['1']['0']['count_lv2'] || 0}</Table.Cell>
                                                                </Table.Row>
                                                            }
                                                        })
                                                    }
                                                </Table.Body>
                                            </Table>
                                            {
                                                Object.entries(this.state.countUserDetail).length === 0 &&
                                                <center style={{ width: '100%' }}><Message info content='ไม่มียอดจำนวนของสมาชิก' style={{ textAlign: 'center', borderRadius: '0 0 0.28571429rem 0.28571429rem' }} /></center>
                                            }
                                        </div>
                                    </Grid.Column>
                                }
                            </>
                            :
                            <Button fluid style={{ borderRadius: 15, background: 'var(--background-button-invite)', marginLeft: '1rem', marginRight: '1rem', color: '#FFF', padding: '15px 0', fontSize: '1.3em' }} onClick={this.getChildrenDetail} loading={this.state.isLoadChildrenDetail}>ดูยอดแทงสมาชิก</Button>
                    }


                    <Grid.Column style={{ width: '100%', paddingTop: 5, borderRadius: 15 }}>
                        <Image src={config.AF_TYPE == '1' ? require('../../../assets/images/af_friend_4.jpeg') : require('../../../assets/images/af_friend_7.jpeg')} width={'100%'} height="auto" style={{ borderRadius: 15 }} />
                    </Grid.Column>

                    <ToastContainer autoClose={1500} transition={Slide} />

                    {/* <Grid.Column mobile={16} tablet={16} computer={16} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        {(this.state.childrenList.length == 0) ? (
                            <center><Message info content='ยังไม่มีสมาชิก' style={{ textAlign: 'center' }} /></center>
                        ) : (
                            <div>

                                <Button color='blue' fluid style={{ borderRadius: 2, background: '#1678c2' }}>สมาชิก</Button>
                                <Table unstackable style={{ marginTop: 0, paddingLeft: 10, paddingRight: 10, paddingBottom: 20, border: 0, boxShadow: 'rgba(34, 36, 38, 0.1) 0px 2px 4px 0px' }}>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell width="3" style={{ paddingLeft: 10 }}>เบอร์โทรศัพท์</Table.Cell>
                                            <Table.Cell width="1">วันที่สมัคร</Table.Cell>
                                            <Table.Cell width="1">AF</Table.Cell>
                                        </Table.Row>
                                        {this.state.childrenList.map((item, key) => (<>
                                            <Table.Row key={key} style={{ color: '#888', cursor: 'pointer' }} onClick={() => this.setState(oldstate => ({ expandIndex: oldstate.expandIndex == key ? -1 : key }))}>
                                                <Table.Cell><Icon name='dropdown' style={{ color: '#CCC', fontSize: 12 }} size='mini' />{item.username}</Table.Cell>
                                                <Table.Cell>{new Date(item.createdAt).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })}</Table.Cell>
                                                <Table.Cell>{Number(item.sumAFOutcome?.L1 || 0).toFixed(2)}</Table.Cell>
                                            </Table.Row>
                                            {item.children.length > 0 && (
                                                <Table.Row key={key + "child"} style={{ display: this.state.expandIndex == key ? 'contents' : 'none' }}>
                                                    <Table.Cell colSpan='2' style={{ background: '#F8F8F8', padding: 3, paddingLeft: '25px', color: '#777', fontSize: 13 }}>
                                                        <Table unstackable style={{ background: '#F8F8F8', border: 0, color: '#999' }}><Table.Body>
                                                            {item.children.map((childrenItem, key2) => (
                                                                <Table.Row>
                                                                    <Table.Cell width="3">{childrenItem.username}</Table.Cell>
                                                                    <Table.Cell width="1" >{new Date(childrenItem.createdAt).toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })}</Table.Cell>
                                                                    <Table.Cell width="1">{Number(item.sumAFOutcome?.L2 || 0).toFixed(2)}</Table.Cell>
                                                                </Table.Row>
                                                            ))}
                                                        </Table.Body></Table>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}</>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                        )}
                    </Grid.Column> */}
                </Grid>
            </Container >
        )
    }
}

export default Invite