import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Container, Modal, Icon, Dropdown, Table, Popup, Segment, Confirm, Image, Button } from "semantic-ui-react";
import BookService from '../../../service/BookService';

const FavoriteBill = (props) => {
    const [loading, set_loading] = useState(true)
    const [list_bill, set_list_bill] = useState([])
    const [openConfirm, setOpenConfirm] = useState({
        status: false,
        id: ''
    })

    useEffect(() => {
        BookService.favoriteOrder().then((res) => {
            set_list_bill(res.data)
            set_loading(false)
        })
    }, [loading])

    const deleteFavoriteOrder = (id) => {
        BookService.deleteFavoriteOrder({ id: id }).then((res) => {
            if (res.status) {
                setOpenConfirm({
                    status: false,
                    id: ''
                })
                set_loading(true)
            }
        })
    }

    return (
        <Container className='lotteryUI' style={{ marginTop: 10 }}>
            <Segment style={{
                background: 'var(--background-head-menu-item-active)',
                color: 'var(--color-head-menu-item-active)',
                border: '2px solid var(--border-color-head-menu-item-active)',
                padding: '10px 10px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: 0,
                fontSize: '1.4em',
                borderRadius: 15
            }}>
                <div>
                    <Icon name={'list ol'} />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', width: '100%' }}>
                    <div>
                        จัดการเลขชุด
                    </div>
                    {/* <Button as={Link} to='/createFavoriteBill' size="mini" style={{ background: '#FFF', color: '#000', fontSize: '0.9rem', borderRadius: 8, padding: 10 }} ><Icon name={'plus'} style={{ marginRight: 3 }} />สร้างเลขชุด</Button> */}
                    <Button as={Link} to='/home' size="mini" style={{
                        background: '#E7276C',
                        color: '#fff',
                        fontSize: '1rem',
                        borderRadius: 8,
                        padding: 10
                    }} ><Icon name={'angle left'} style={{ marginRight: 3 }} />กลับสู่หน้าหลัก</Button>
                </div>
            </Segment>
            <Segment
                loading={loading}
                style={{
                    background: 'var(--background-head-menu-item)',
                    color: 'var(--color-head-menu-item)',
                    border: 'var(--border-color-head-menu-item)',
                    padding: 5,
                    borderRadius: 10,
                    margin: '5px 0'
                }}>
                <Button as={Link} to='/createFavoriteBill' size="mini" style={{ background: '#00C013', color: '#FFF', fontSize: 18, width: '100%', borderRadius: 8, padding: 15 }} ><Icon name={'plus'} style={{ marginRight: 3, fontSize: 14, marginTop: -5 }} />สร้างเลขชุด</Button>
            </Segment>
            <Segment
                loading={loading}
                style={{
                    background: 'var(--background-head-menu-item)',
                    padding: 8,
                    borderRadius: 15,
                    marginTop: 0
                }}>
                {/* <Table unstackable style={{ border: 0, margin: 0 }}>
                    <Table.Row style={{ background: 'rgba(255, 92, 0, 1)', color: '#FFF' }}>
                        <Table.Cell style={{ width: '30%', textAlign: 'left' }}>ชื่อเลขชุด</Table.Cell>
                        <Table.Cell style={{ width: '45%', textAlign: 'center' }}>
                            วันที่สร้าง
                        </Table.Cell>
                        <Table.Cell style={{ width: '25%', textAlign: 'center' }}>
                            จัดการ
                        </Table.Cell>
                    </Table.Row>
                </Table> */}
                {
                    list_bill.length > 0 ?
                        list_bill.map((bill, index) => {
                            return (
                                <Card
                                    style={{
                                        width: '100%',
                                        margin: index != 0 ? '10px 0 0' : 0,
                                        color: "#000",
                                        borderRadius: 10
                                    }}
                                // as={Link}
                                // to={{
                                //     pathname: '/createFavoriteBill',
                                //     query: { data: bill }
                                // }}
                                >
                                    <Card.Content style={{ padding: '0.8em', display: 'flex', alignItems: 'center' }}>
                                        <Icon name="caret right" style={{ padding: 0, fontSize: '1.5em' }} />
                                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                            <div style={{ fontSize: '1.2em', fontWeight: 500 }} className="name-favorite-bill">{bill.name}</div>
                                            <div style={{ fontSize: '0.9em', fontWeight: 400 }}>
                                                {`${new Date(bill.updatedAt).toLocaleDateString('th-TH')}, ${new Date(bill.updatedAt).getHours()}:${new Date(bill.updatedAt).getMinutes()}:${new Date(bill.updatedAt).getSeconds()}`}
                                            </div>
                                        </div>
                                        <div>
                                            <Button
                                                style={{
                                                    color: '#FFA800',
                                                    background: '#000',
                                                    margin: 0,
                                                    marginRight: 5,
                                                    fontSize: 18,
                                                    padding: 10,
                                                    borderRadius: 10
                                                }}
                                                size="mini"
                                                as={Link}
                                                to={{
                                                    pathname: '/createFavoriteBill',
                                                    query: { data: bill }
                                                }}
                                            >
                                                <Icon style={{ margin: '0 0 0 2px' }} name={'edit'} />
                                            </Button>
                                            <Button
                                                style={{
                                                    color: '#FF0000',
                                                    background: '#000',
                                                    margin: 0,
                                                    fontSize: 18,
                                                    padding: 10,
                                                    borderRadius: 10
                                                }}
                                                size="mini"
                                                onClick={() => setOpenConfirm({
                                                    status: true,
                                                    id: bill._id
                                                })}
                                            >
                                                <Icon style={{ margin: 0 }} name={'trash'} />
                                            </Button>
                                        </div>
                                    </Card.Content>
                                </Card>
                                // < Table unstackable style={{ border: 0, margin: 0, borderRadius: 0 }}>
                                //     <Table.Row style={{ background: (index % 2 === 0) ? '#140101' : '#271212', color: '#FFF' }}>
                                //         <Table.Cell style={{ width: '30%', textAlign: 'left', paddingRight: 0 }}>
                                //             {bill.name}
                                //         </Table.Cell>
                                //         <Table.Cell style={{ width: '45%', textAlign: 'center' }}>
                                //             {`${new Date(bill.updatedAt).toLocaleDateString('th-TH')}, ${new Date(bill.updatedAt).getHours()}:${new Date(bill.updatedAt).getMinutes()}:${new Date(bill.updatedAt).getSeconds()}`}
                                //         </Table.Cell>
                                //         <Table.Cell style={{ width: '25%', textAlign: 'center' }}>
                                //             <Dropdown
                                //                 text='จัดการ'
                                //                 style={{ color: 'rgba(255, 168, 0, 1)' }}
                                //             >
                                //                 <Dropdown.Menu>
                                //                     <Dropdown.Item as={Link} to={{
                                //                         pathname: '/createFavoriteBill',
                                //                         query: { data: bill }
                                //                     }}
                                //                     >รายละเอียด</Dropdown.Item>
                                //                     {/* <Dropdown.Item onClick={() => deleteFavoriteOrder(bill._id)}>ลบ</Dropdown.Item> */}
                                //                     <Dropdown.Item onClick={() => setOpenConfirm({
                                //                         status: true,
                                //                         id: bill._id
                                //                     })}>ลบ</Dropdown.Item>
                                //                 </Dropdown.Menu>
                                //             </Dropdown>
                                //         </Table.Cell>
                                //     </Table.Row>
                                // </Table>
                            )
                        })
                        :
                        <Table unstackable style={{ border: 0, margin: 0, borderRadius: 0 }}>
                            <Table.Row style={{ background: '#140101', color: '#FFF' }}>
                                <Table.Cell style={{ width: '100%', textAlign: 'center', padding: '8px 0' }}>
                                    ไม่มีเลขชุด
                                </Table.Cell>
                            </Table.Row>
                        </Table>
                }

                <Modal
                    onClose={() => setOpenConfirm({
                        status: false,
                        id: ''
                    })}
                    open={openConfirm.status}
                    size="mini"
                >
                    <Modal.Content style={{ textAlign: 'center' }}>
                        <Icon name="exclamation circle" size="huge" style={{ color: '#f8bb86' }} />
                        <p style={{ marginTop: 10, fontSize: '1.2rem', fontWeight: 400 }}>ยืนยันการลบเลขชุด ?</p>
                    </Modal.Content>
                    <Modal.Actions style={{ textAlign: 'center' }}>
                        <Button
                            content="ยกเลิก"
                            style={{ margin: '0 0.25rem 0 0' }}
                            onClick={() => setOpenConfirm({
                                status: false,
                                id: ''
                            })}
                            color={'blue'}
                        />
                        <Button
                            content="ยืนยัน"
                            style={{ margin: 0 }}
                            onClick={() => {
                                deleteFavoriteOrder(openConfirm.id)
                            }}
                            color={'red'}
                        />
                    </Modal.Actions>
                </Modal>
                {/* <Confirm
                    content='ต้องการลบเลขชุด ?'
                    open={openConfirm.status}
                    onCancel={() => setOpenConfirm({
                        status: false,
                        id: ''
                    })}
                    onConfirm={() => {
                        deleteFavoriteOrder(openConfirm.id)
                    }}
                    cancelButton='ยกเลิก'
                    confirmButton="ยืนยัน"
                /> */}

                {/* 
                <Modal size="mini" open={openConfirm} onClose={() => setOpenConfirm(false)}>
                    <Modal.Header>ลบเลขชุด</Modal.Header>
                    <Modal.Content>
                        <p>ต้องการลบเลขชุด ?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative>ยืนยัน</Button>
                        <Button onClick={() => setOpenConfirm(false)} positive content='ยกเลิก' />
                    </Modal.Actions>
                </Modal> */}

            </Segment>
        </Container >
    )
}

export default FavoriteBill