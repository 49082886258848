import Config from "../config/config";
import Authen from './Authen'
import WalletService from './WalletService'
import apiClient from './axios/apiClient'
const axios = require('axios');

export default class ConfigValueService {
    constructor() {
    }

    static async MINIMUM_WITHDRAW_AF() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain+'config/MINIMUM_WITHDRAW_AF',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }

    static async ENABLE_WITHDRAW_CREDIT() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain+'config/ENABLE_WITHDRAW_CREDIT',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
        };

        const returnData = await apiClient(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }

    static async MINIMUM_WITHDRAW_CREDIT() {
        const configWalletData=await WalletService.getConfigBankPlaymentSystem()
        if(!configWalletData.status)return {status: false ,msg: "ระบบปิด"}

        return {status: true, data: {key: "MINIMUM_WITHDRAW_CREDIT", value:Number(configWalletData.data.config_withdraw)}};
    }

    static async IS_MAINTENANCE_PLAY_SYSTEM() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain+'config/IS_MAINTENANCE_PLAY_SYSTEM',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
        };

        const returnData = await axios(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }

    static async LOTTORY_TYPES() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain+'config/LOTTORY_TYPES',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
        };

        const returnData = await axios(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }

    static async INIT_AF() {
        var config = {
            method: 'get',
            url: Config.APIConfig.apiDomain+'config/INIT_AF',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+Authen.getAcessToken(),
            },
        };

        const returnData = await axios(config).then(function (response) {
            return {status: true, data: response.data}
        }).catch(function (error) {
            return {status: false ,msg: error.response.data.errors.message}
        });
        return await returnData
    }
    
}