import React, { useEffect, useState } from 'react'
import { Segment, Image, Container, Loader, Dimmer, Modal, Button } from 'semantic-ui-react';
import { Wheel } from 'react-custom-roulette'
import UserService from '../../../service/UserService';
import moment from 'moment'
import Config from '../../../config/config';

export default () => {
    const [data, setDate] = useState([
        { option: 0, style: { backgroundColor: '#000', textColor: '#fff' } },
        { option: 10, style: { backgroundColor: '#fff', textColor: '#000' } },
        { option: 20, style: { backgroundColor: '#000', textColor: '#fff' } },
        { option: 50, style: { backgroundColor: '#fff', textColor: '#000' } },
        { option: 100, style: { backgroundColor: '#000', textColor: '#fff' } },
        { option: 150, style: { backgroundColor: '#fff', textColor: '#000' } },
        { option: 200, style: { backgroundColor: '#000', textColor: '#fff' } },
        { option: 300, style: { backgroundColor: '#fff', textColor: '#000' } },
    ])
    const [config, setConfig] = useState()
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [resultPlay, setResultPlay] = useState(0);

    useEffect(() => {
        // if (Config.ENABLE_BONUS != "true") {
        //     window.location.replace("/")
        // }
        getConfig(true)
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "visible";
        }
    }, [])

    const getConfig = (first) => {
        UserService.getDailyRewardGame().then(res => {
            if (res.status) {
                if (res.data.data.enable == false) {
                    window.location.replace("/")
                }
                let _data = []
                let _canplay = getCanPlayBonus(res.data.data.timeStart, res.data.data.timeEnd)
                setConfig({
                    ...res.data.data,
                    canplay: _canplay
                })
                if (!!_canplay && res.data.data.playCredit > 0) {
                    res.data.data.pizza.map((item, index) => {
                        _data = [..._data, { option: item.toString(), style: (index + 1) % 2 == 1 ? { backgroundColor: '#000', textColor: '#fff', fontSize: 20 } : { backgroundColor: '#fff', textColor: '#000', fontSize: 20 } }]
                    })
                    setDate(_data)
                } else {
                    res.data.data.pizza.map((item, index) => {
                        _data = [..._data, { option: item.toString(), style: (index + 1) % 2 == 1 ? { backgroundColor: '#000', textColor: '#fff', fontSize: 20 } : { backgroundColor: '#fff', textColor: '#000', fontSize: 20 } }]
                    })
                    setDate(_data)
                    setModalVisible(true)
                }
                if (first == true) {
                    setLoadingPage(false)
                }
            }
        })
    }

    const handleSpinClick = () => {
        setLoading(true)
        UserService.playDailyRewardGame().then(async res => {
            let _result = data.findIndex((item) => item.option == res.data.data.reward)
            await setPrizeNumber(_result)
            await setResultPlay(res.data.data.reward)
            setMustSpin(true)
        })
    }

    const getCanPlayBonus = (start, end) => {
        let [time, zoneTime] = moment(new Date()).format('hhmmss a').split(' ')
        let _time = parseInt(time)
        const timeStart = parseInt(start || config?.timeStart.replaceAll(':', ''))
        const timeEnd = parseInt(end || config?.timeEnd.replaceAll(':', ''))
        if (zoneTime == "pm") {
            _time = _time + 120000
        }
        return !(timeStart < _time && timeEnd > _time)
    }

    const getCanPlayTime = (start, end) => {
        let [time, zoneTime] = moment(new Date()).format('hhmmss a').split(' ')
        let _time = parseInt(time)
        let timeStart = parseInt(start || config?.timeStart.replaceAll(':', ''))
        if (zoneTime == "pm") {
            _time = _time + 120000
        }
        if ((timeStart - _time).toString().substring(0, 3) == "-12") {
            return 23
        } else if (((240000 + timeStart) - _time).toString()?.length == 4) {
            return 1
        } else if (((240000 + timeStart) - _time).toString()?.length == 5) {
            return ((240000 + timeStart) - _time).toString()?.charAt(0)
        } else {
            return `${((240000 + timeStart) - _time).toString()?.charAt(0)}${((240000 + timeStart) - _time).toString()?.charAt(1)}`
        }
    }

    return (
        <Segment className='div_wheel' loading={loadingPage}>
            <Container className={"container_bonus"} >
                <Modal
                    open={modalVisible}
                    closeIcon
                    onClose={() => {
                        if (config?.playCredit != 0) {
                            getConfig(true)
                            setModalVisible(false)
                        }
                        setModalVisible(false)
                    }}
                >
                    <Modal.Header>
                        {resultPlay > 0 ? 'ยินดีด้วย' : 'เสียใจด้วย'}
                    </Modal.Header>
                    <Modal.Content>

                        <center style={{ fontSize: 15 }}>
                            {resultPlay > 0 ? `คุณได้ AF ${resultPlay} บาท` :
                                (
                                    !config?.canplay ?
                                        `กรุณารอเล่นในช่วงเวลา ${config?.timeStart} - ${config?.timeEnd}`
                                        :
                                        (
                                            config?.playCredit != 0 ?
                                                'รอลุ้นใหม่วันถัดไป'
                                                :
                                                `สิทธิ์การสุ่มของคุณหมดแล้วรอลุ้นใหม่ในอีก ${getCanPlayTime()} ชั่วโมง`
                                        )
                                )
                            }
                        </center>
                    </Modal.Content>
                    <Modal.Actions>
                        <center>
                            <Button
                                style={{ background: '#4CAE3C', borderRadius: '30px', paddingInlineStart: '50px', paddingInlineEnd: '50px' }}
                                type='button'
                                color='primary'
                                onClick={() => {
                                    if (config?.playCredit != 0) {
                                        getConfig(true)
                                        setModalVisible(false)
                                    }
                                    setModalVisible(false)
                                }}>
                                ตกลง
                            </Button>
                        </center>
                    </Modal.Actions>
                </Modal>


                <div className={"container_wheel"} >

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            position: 'relative',
                            width: 250,
                            height: 100,
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Image src={require('../../../assets/images/home/bonus_topic.png')} style={{
                                width: 220,
                                height: 100,
                                zIndex: 1
                            }} />
                            <Image src={require('../../../assets/images/home/bg_topic.png')} style={{
                                width: 250,
                                height: 100,
                                position: 'absolute',
                                top: 0,
                                zIndex: 0
                            }} />
                        </div>
                        <div style={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            marginTop: -20
                        }}>
                            <Image src={require('../../../assets/images/home/bg_wheel.png')} style={{
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                top: 0,
                                zIndex: 0
                            }} />
                            <div className={"content_wheel"}>
                                <Wheel
                                    mustStartSpinning={mustSpin}
                                    prizeNumber={prizeNumber}
                                    data={data}
                                    onStopSpinning={() => {
                                        // getConfig(false)
                                        setModalVisible(true)
                                        setLoading(false)
                                        // setConfig({ ...config, playCredit: 0 })
                                        setMustSpin(false)
                                    }}
                                    style={{
                                        width: '70vw',
                                        height: '70vh',
                                    }}
                                    fontSize={20}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{
                        position: 'relative',
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                        position: 'absolute',
                        bottom: 30
                    }}>
                        <Image
                            className='button_bonus'
                            src={require('../../../assets/images/home/backgroun_button_bonus.png')}
                            style={{ height: 250, }}
                        />
                        <Image
                            className='button_bonus_mobile'
                            src={require('../../../assets/images/home/backgroun_button_bonus_mobile.png')}
                            style={{
                                width: '100%',
                                height: 250,
                            }}
                        />
                        {
                            (config?.canplay || config?.playCredit > 0) &&
                            (config?.playCredit > 0 || loading) &&
                            <div className='bonus_button' onClick={(loading) ? undefined : handleSpinClick} >
                                {loading ?
                                    <Dimmer active style={{ backgroundColor: 'transparent' }}>
                                        <Loader />
                                    </Dimmer>
                                    :
                                    `สุ่ม 1 ครั้ง`
                                }
                            </div>
                        }
                    </div>
                </div>
            </Container>
        </Segment>
    )
}