import React, { Component } from 'react'
import { Card, Container, Grid, Header, Icon, Label, Responsive, Segment, Progress, Image } from "semantic-ui-react";
import WalletHeader from "../wallet/WalletHeader";
import { Link } from "react-router-dom";
import ReportLowHighTable from "./ReportLowHighTable";
import YiiGiiService from '../../../service/YiiGiiService';

const asyncFunc = (event) => {
    return new Promise(resolve => {
        event();
        resolve();
    });
};

class Lottery extends Component {
    playRoundStatus = { standby: 0, running: 1, end: 2 }

    constructor(props) {
        super(props);
        this.state = {
            roundMain: 2,
            twoTopResultList: [],
            twoBotResultList: [],
            roundData: [],
            playRoundCountdownData: [],
            huayData: null,
            dateFilter: new Date(),
            activeHeaderMenu: [false, true, false],
            onloading: true
        };
    }

    async componentDidMount() {
        this.resizeGame()
        await asyncFunc(() => {
        });
        this.LoadreportLowHighTableData()
    }
    componentWillMount() {
        this.initData(this.state.dateFilter)
    }

    async componentWillUnmount() {
        clearInterval(this.syncDataPlayRound);
    }

    async initData(dateFilter) {
        this.setState({ onloading: true })
        await YiiGiiService.getYiiGiiRoundByDate(this.props.match.params.yiigiiID, dateFilter).then(async res => {
            if (res.status) {
                this.setState({ huayData: res.data.yiigii })
                this.setState({ roundData: res.data.round })
                this.setState({ onloading: false })
            }
        })
        this.setInitPlayRoundCountdownData()
        this.LoadreportLowHighTableData()
    }

    syncDataPlayRound = setInterval(() => {
        let tempState = this.state.playRoundCountdownData
        var newStateData = tempState.map((item, key) => {
            if (item.statusDuration > 0) {
                item.statusDuration--
            } else {
                // clearInterval(this.syncDataPlayRound);
                this.initData(this.state.dateFilter)
            }
            // newStateData.push(newStateDataItem)
            return item
        })
        this.setState({ playRoundCountdownData: newStateData })
    }, 1000);

    resizeGame = () => {
        let roundMain = 2;
        let innerWidth = window.innerWidth;
        if (innerWidth > 620) {
            roundMain = 2;
        } else {
            roundMain = 1;
        }
        this.setState((prevState) => ({ roundMain: roundMain }))
    };


    LoadreportLowHighTableData() {
        let newTwoTopResultList = []
        let newTwoBotResultList = []
        this.getRoundClosingList().forEach(roundItem => {
            if (roundItem.result) {
                newTwoTopResultList.push({ socre: parseInt(roundItem.result.twoTop), N: parseInt(roundItem.name) })
                newTwoBotResultList.push({ socre: parseInt(roundItem.result.twoBot), N: parseInt(roundItem.name) })
            }
        });

        this.setState({
            twoTopResultList: newTwoTopResultList,
            twoBotResultList: newTwoBotResultList
        })
    }

    setInitPlayRoundCountdownData() {
        let initData = [];
        this.getRoundBuyingList().forEach((item, key) => {
            if (key <= 1) {
                let tempItem = item;
                tempItem.duration = 60 * 15;
                tempItem.progressType = 1; // 1:endBuyingTime , 2:endNumberShotTime
                let newStatusDuration = ((new Date(tempItem.endBuyingTime)) - (new Date())) / 1000
                if (newStatusDuration <= 0) {
                    tempItem.progressType = 2;
                    newStatusDuration = ((new Date(tempItem.endNumberShotTime)) - (new Date())) / 1000
                }
                tempItem.statusDuration = newStatusDuration;
                initData.push(tempItem)
            }
        })
        this.setState({ playRoundCountdownData: initData })
    }

    getDurationToTime = (duration) => {
        var timer = duration, minutes, seconds;
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        if (--timer < 0) {
            timer = duration;
        }

        return [minutes, seconds]
    }

    getRoundBuyingList() {
        return this.state.roundData.filter(function (item, index) {
            return new Date() <= new Date(item.endBuyingTime) || new Date() <= new Date(item.endNumberShotTime)
        });
    }

    getRoundClosingList() {
        return this.state.roundData.filter(function (item, index) {
            return new Date() > new Date(item.endBuyingTime) && new Date() > new Date(item.endNumberShotTime)
        }).reverse();
    }

    changeDatefilter(dateObject, activeIndex) {
        const activeHeaderMenu = [false, false, false]
        activeHeaderMenu[activeIndex] = true
        this.setState({ dateFilter: dateObject, activeHeaderMenu: activeHeaderMenu })
        this.initData(dateObject)
    }

    changeRules(activeIndex) {
        const activeHeaderMenu = [false, false, false]
        activeHeaderMenu[activeIndex] = true
        this.setState({ activeHeaderMenu: activeHeaderMenu })
    }

    render() {
        return (
            <Container>
                <div>
                    <Card style={{ width: '100%', borderRadius: 10, background: 'rgba(0, 0, 0, 0.40)' }}>
                        <Card.Content style={{ display: 'flex', textAlign: 'center', padding: 0 }}>
                            <a style={{ borderRadius: 10, width: '33%', padding: 8, color: 'var(--color-head-menu-item)', fontSize: '1.2rem', background: 'var(--background-head-menu-item)', border: '2px solid var(--border-color-head-menu-item)', margin: '2px 0px 2px 2px' }} className={this.state.activeHeaderMenu[1] ? 'link-active' : ''} onClick={() => this.changeDatefilter(new Date(), 1)}>
                                วันนี้
                            </a>
                            <a style={{ borderRadius: 10, width: '33%', padding: 8, color: 'var(--color-head-menu-item)', fontSize: '1.2rem', background: 'var(--background-head-menu-item)', border: '2px solid var(--border-color-head-menu-item)', margin: 2 }} className={this.state.activeHeaderMenu[2] ? 'link-active' : ''} onClick={() => this.changeDatefilter(new Date().setDate(new Date().getDate() + 1), 2)}>
                                พรุ่งนี้
                            </a>
                            <a style={{ borderRadius: 10, width: '33%', padding: 8, color: 'var(--color-head-menu-item)', fontSize: '1.2rem', background: 'var(--background-head-menu-item)', border: '2px solid var(--border-color-head-menu-item)', margin: '2px 2px 2px 0px' }} className={this.state.activeHeaderMenu[3] ? 'link-active' : ''} onClick={() => this.changeRules(3)}>
                                กติกา
                            </a>
                        </Card.Content>
                    </Card>
                </div>
                {
                    !this.state.activeHeaderMenu[3] ? <>
                        {/* <div style={{
                            // justifyContent: 'center',
                            // display: 'flex',
                            paddingTop: 5
                        }}>
                            <Card style={{
                                width: '100%',
                                borderRadius: 10,
                                border: '2.5px solid var(--background-head-menu)',
                                background: '#FFF',
                                marginTop: 3,
                            }}>
                                <Card.Content style={{ display: 'flex', textAlign: 'center', padding: 0 }}>
                                    <a style={{ width: '100%', padding: '0px 0px 3px', color: 'var(--background-head-menu)', fontSize: '1.2rem', fontWeight: 400 }} target="_blank" href="https://yeekeeparuay.com/">
                                        <Image src={require('../../../assets/images/yiki_text.png')} style={{ width: '8rem', height: '1.8rem', marginTop: -2 }} />
                                    </a>
                                </Card.Content>
                            </Card>
                        </div> */}

                        <Grid>
                            <Grid.Row style={{ paddingTop: 5 }}>
                                <Grid.Column>
                                    <Responsive as={Segment} onUpdate={this.resizeGame} className="customRoundContainer" loading={this.state.onloading} >
                                        <div style={{ paddingBottom: 25 }}>
                                            {this.state.twoTopResultList.length > 0 ? (
                                                <Segment style={{ marginBottom: 5, paddingLeft: 0, border: '3px solid var(--background-head-menu)', borderRadius: 20 }}>

                                                    <ReportLowHighTable dataList={this.state.twoTopResultList} title="2 บน" />
                                                    <div style={{ marginTop: 10, textAlign: 'center' }}><h4>2 บน</h4></div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
                                                        <div style={{ display: 'flex', color: '#2962ff', paddingRight: 10 }}><span style={{ width: 20, height: 20, borderRadius: '50%', background: '#2962ff', marginRight: 3 }}></span><span>เลขต่ำ (00-49) </span></div>
                                                        <div style={{ display: 'flex', color: '#e53935', paddingLeft: 10 }}><span style={{ width: 20, height: 20, borderRadius: '50%', background: '#e53935', marginRight: 3 }}></span><span>เลขสูง (50-99) </span></div>
                                                    </div>
                                                </Segment>
                                            ) : ''}
                                            {this.state.twoBotResultList.length > 0 ? (
                                                <Segment style={{ marginBottom: 5, paddingLeft: 0, border: '3px solid var(--background-head-menu)', borderRadius: 20 }}>
                                                    <ReportLowHighTable dataList={this.state.twoBotResultList} title="2 ล่าง" />
                                                    <div style={{ marginTop: 10, textAlign: 'center' }}><h4>2 ล่าง</h4></div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
                                                        <div style={{ display: 'flex', color: '#2962ff', paddingRight: 10 }}><span style={{ width: 20, height: 20, borderRadius: '50%', background: '#2962ff', marginRight: 3 }}></span><span>เลขต่ำ (00-49) </span></div>
                                                        <div style={{ display: 'flex', color: '#e53935', paddingLeft: 10 }}><span style={{ width: 20, height: 20, borderRadius: '50%', background: '#e53935', marginRight: 3 }}></span><span>เลขสูง (50-99) </span></div>
                                                    </div>
                                                </Segment>
                                            ) : ''}
                                        </div>

                                        {/* <Header as='h2'>รอบล่าสุด  <Icon color="yellow" name="star"/></Header> */}
                                        <Card.Group itemsPerRow={1}>
                                            {/* {this.getRoundClosingList().map((item,key) => {if(key <= 0) return(
                                    <Card color='red' as={Link} to={'/lotteryDetail/'+item._id} key={item._id} >
                                        <Card.Content>
                                            <Card.Header>
                                                <span style={{color: "#000"}}>{this.state.huayData.name}</span>
                                                <Label className="float-right" color='teal' tag>รอบที่ {item.name}</Label>
                                            </Card.Header>
                                            <Card.Description>
                                        <span style={{
                                            marginTop: "32px",
                                            display: "block",
                                        }}>รอบนี้ปิด</span>
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content extra>
                                            <Icon name='dont' color='red'/>
                                            <Label className="float-right" color='red'>
                                                ปิดรับแทง
                                            </Label>
                                        </Card.Content>
                                    </Card>
                                    )})} */}
                                            {this.state.playRoundCountdownData.map((item, key) => (
                                                <Card as={Link} to={(item.progressType == 1) ? '/lotteryPlay/yiigii/' + item._id : '/yiigiinumberShot/' + item._id} key={item._id} style={{ border: '3px solid var(--background-head-menu)', borderRadius: 20 }} >
                                                    <Card.Content style={{ padding: 15 }}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10 }}>
                                                            <div style={{ flex: 4 }}>
                                                                <h3 style={{ marginBottom: 3 }}>{this.state.huayData.name} <span>รอบที่ {item.name}</span></h3>
                                                                <span style={{ fontWeight: 300, fontSize: 13 }}>ปิดรับแทง {new Date(item.endBuyingTime).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)} น.</span>
                                                            </div>
                                                            <div style={{ flex: 2, borderLeft: '2px dashed #CCC', textAlign: 'center' }}>
                                                                <h1 style={{ marginBottom: 5 }}>{this.getDurationToTime(item.statusDuration)[0] + ":" + this.getDurationToTime(item.statusDuration)[1]}</h1>
                                                                <span style={{ fontWeight: 300, fontSize: 13 }}>{(item.progressType == 1) ? 'เวลาแทงเหลือ' : 'เวลายิงเลขเหลือ'} (นาที)</span>
                                                            </div>
                                                        </div>
                                                        <div style={{ paddingBottom: 10 }} >
                                                            <Progress value={item.statusDuration} total={item.duration} indicating size='small' />
                                                        </div>
                                                    </Card.Content>
                                                </Card>
                                            ))}
                                        </Card.Group>
                                    </Responsive>
                                    <Segment className="customRoundContainer">
                                        {/* <Header as='h2'>รอบที่เหลือ <Icon color="yellow" name="star"/></Header> */}
                                        <Card.Group itemsPerRow={this.state.roundMain}>
                                            {this.getRoundBuyingList().map((item, key) => {
                                                if (key >= 2) return (
                                                    <Card color='green' as={Link} to={'/lotteryPlay/yiigii/' + item._id} key={item._id}>
                                                        <Card.Content style={{ padding: '15px 0px 15px 0px' }}>
                                                            <div style={{ width: 40, height: 20, background: 'rgb(243, 243, 243)', position: 'absolute', left: '53.5%', top: '0', borderRadius: '0px 0px 40px 40px' }}></div>
                                                            <div style={{ width: 40, height: 20, background: 'rgb(243, 243, 243)', position: 'absolute', left: '53.5%', bottom: '0', borderRadius: '40px 40px 0px 0px' }}></div>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0px 10px 0px' }}>
                                                                <div style={{ flex: 3, textAlign: 'center' }}>
                                                                    <div style={{ paddingRight: 20 }}>
                                                                        <h3 style={{ marginBottom: 3 }}>{this.state.huayData.name}</h3>
                                                                        <h3 style={{ margin: 0 }}>รอบที่ {item.name}</h3>
                                                                    </div>
                                                                </div>
                                                                <div style={{ flex: 2, borderLeft: '2px dashed #CCC', textAlign: 'center', color: 'var(--color-open-yiki-time)' }}>
                                                                    <h4 style={{ marginBottom: 3 }}>เปิดถึงเวลา</h4>
                                                                    <h4 style={{ margin: 0 }}>{new Date(item.endBuyingTime).toLocaleTimeString('en-US', { hour12: false }).substring(0, 5)} น.</h4>
                                                                </div>
                                                            </div>
                                                        </Card.Content>
                                                    </Card>
                                                )
                                            })}
                                        </Card.Group>
                                    </Segment>
                                    {/* <Segment className="customRoundContainer">
                                <Card.Group itemsPerRow={this.state.roundMain} >
                                    {this.getRoundClosingList().map((item,key) => {if(key >= 1) return(
                                    <Card color='red' as={Link} to={'/lotteryDetail/'+item._id} key={item._id} >
                                        <Card.Content>
                                            <Card.Header>
                                                <span style={{color: "#000"}}>{this.state.huayData.name}</span>
                                                <Label className="float-right" color='red' tag>
                                                    รอบที่ {item.name}
                                                </Label>
                                            </Card.Header>
                                        </Card.Content>
                                        <Card.Content extra>
                                            <Icon name='dont' color='red'/>
                                            <Label className="float-right" color='red'>
                                                ปิดรับแทง
                                            </Label>
                                        </Card.Content>
                                    </Card>
                                    )})}
                                </Card.Group>
                            </Segment> */}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </>
                        :
                        <>
                            <Segment >
                                <Card centered>
                                    <div class="content-rules-huay" dangerouslySetInnerHTML={{ __html: this.state.huayData?.rules }} />
                                </Card>
                            </Segment>
                        </>}
            </Container>
        );
    }
}

export default Lottery;